import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  isBarAndBench,
  isCDR,
  isDigipub,
  isHotStar,
  isMuthoot,
  isNbf,
  isNSE,
  isOrix,
  isStar,
  isViacom,
  isVikalp,
  isWebnyay,
} from "Routes/Routes";
import { getCompaniesByGroup } from "slices/companySlice";
import Intro from "./intro";

export const introFormContext = React.createContext();

export default function IntroContainer() {
  const { user } = useSelector((state) => state.user, shallowEqual);
  const { profile } = useSelector((state) => state.profile, shallowEqual);
  const { companies } = useSelector((state) => state.companies, shallowEqual);
  const [claimantFormValues, setClaimantFormValues] = React.useState({});
  const [formStep, setFormStep] = React.useState(1);
  const [grpName, setGrpName] = React.useState("");

  const dispatch = useDispatch();
  const loading = !profile.user || !user.email || companies.length === 0;

  const IntroFormContextProvider = introFormContext.Provider;

  useEffect(() => {
    if (isDigipub) {
      setGrpName("digipub");
      dispatch(getCompaniesByGroup("digipub"));
    } else if (isViacom) {
      setGrpName("viacom18");
      dispatch(getCompaniesByGroup("viacom18"));
    } else if (isHotStar) {
      setGrpName("hotstar");
      dispatch(getCompaniesByGroup("hotstar"));
    } else if (isStar) {
      setGrpName("star");
      dispatch(getCompaniesByGroup("star"));
    } else if (isMuthoot) {
      setGrpName("muthoot");
      dispatch(getCompaniesByGroup("muthoot"));
    } else if (isNbf) {
      setGrpName("nbf");
      dispatch(getCompaniesByGroup("nbf"));
    } else if (isBarAndBench) {
      setGrpName("barandbench");
      dispatch(getCompaniesByGroup("barandbench"));
    } else if (isNSE) {
      setGrpName("nse");
      dispatch(getCompaniesByGroup("nse"));
    } else if (isOrix) {
      setGrpName("orix");
      dispatch(getCompaniesByGroup("orix"));
    } else {
      setGrpName("webnyay");
      dispatch(getCompaniesByGroup("webnyay"));
    }
  }, []);

  function filterCompnay(filter) {
    let result = [];
    companies.forEach((item) => {
      if (item.platform_type == filter) result.push(item);
    });
    return result;
  }

  let companys = "";
  if (grpName === "webnyay") {
    if (isWebnyay || isViacom || isDigipub) {
      companys = filterCompnay("grievance");
    } else if (isCDR || isVikalp) {
      // cdr.webnyay.in
      companys = filterCompnay("cdr");
    } else {
      companys = companies;
    }
  }

  if (loading) return <CircularCenteredLoader />;
  return (
    <IntroFormContextProvider
      value={{
        isVendor: profile.profile_type === "vendor" ? true : false,
        claimantFormValues,
        setClaimantFormValues,
        setFormStep,
      }}
    >
      <Intro
        loading={loading}
        user={user}
        profile={profile}
        companies={grpName === "webnyay" ? companys : companies}
        formStep={formStep}
        claimantFormValues={claimantFormValues}
      />
    </IntroFormContextProvider>
  );
}
