import React, {useState} from 'react';
import {
    Typography,
    Select,
    MenuItem,
    Button,
    Paper,
    Box,
    makeStyles,
    Grid,
    FormControl,
    InputLabel,
    FormControlLabel,
    Checkbox,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    form: {
      maxWidth: 800,
      margin: '0 auto',
      padding: theme.spacing(3),
      display:  'flex', 
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      marginBottom: theme.spacing(2.5),
      marginTop: theme.spacing(2.5),
    },
    paper: {
      padding: theme.spacing(4),
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      width: 600,
      marginBottom: theme.spacing(4),
      marginTop: theme.spacing(1),
      borderRadius: '10px'
    },
    headingsForm: {
        display: 'flex',
        paddingBottom: '8px',
        marginTop: '24px',
        marginBottom: '24px',
        borderBottom: '1px solid black',
        color: '#000000',
        fontWeight: '700'
    }
}));


function StatementCis() {
    const classes = useStyles();
    const [statementType, setStatementType] = useState("");
    const [fromDate, setFromDate] = useState({ date: "", month: "", year: "" });
    const [toDate, setToDate] = useState({ date: "", month: "", year: "" });
    const [tillDate, setTillDate] = useState(false);

    const handleDateChange = (type, field, value) => {
        if (type === "from") {
            setFromDate((prev) => ({ ...prev, [field]: value }));
        } else {
            setToDate((prev) => ({ ...prev, [field]: value }));
        }
    };

    const renderDropdown = (label, value, onChange, options) => (
        <FormControl fullWidth size="small" variant='outlined'>
            <InputLabel>{label}</InputLabel>
            <Select value={value} onChange={onChange} label={label}>
                {options.map((option) => (
                <MenuItem key={option} value={option}>
                    {option}
                </MenuItem>
                ))}
            </Select>
        </FormControl>
    );

    const dates = Array.from({ length: 31 }, (_, i) => i + 1);
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const years = Array.from({ length: 10 }, (_, i) => 2025 - i);

    return (
        <Box component="form" className={classes.form}>
            <Typography variant="h4" align="center" className={classes.title}>
                Statement
            </Typography>
            <Paper className={classes.paper}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                    <Box p={3} border={1} borderColor="grey.300" borderRadius={2}>
                        <Typography variant="h6" mb={1} className={classes.headingsForm}>
                            Statement Type
                        </Typography>
                        <FormControl fullWidth size="small" margin="dense" variant='outlined'>
                            <InputLabel>Select</InputLabel>
                            <Select
                                value={statementType}
                                onChange={(e) => setStatementType(e.target.value)}
                                label="Select"
                            >
                                <MenuItem value="Account Summary">Account Summary</MenuItem>
                                <MenuItem value="Transaction Report">Transaction Report</MenuItem>
                                <MenuItem value="Billing Statement">Billing Statement</MenuItem>
                            </Select>
                        </FormControl>

                        {/* From Date */}
                        <Typography variant="h6" mt={2} mb={1} className={classes.headingsForm}>
                            From Date
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                            {renderDropdown(
                                "Date",
                                fromDate.date,
                                (e) => handleDateChange("from", "date", e.target.value),
                                dates
                            )}
                            </Grid>
                            <Grid item xs={4}>
                            {renderDropdown(
                                "Month",
                                fromDate.month,
                                (e) => handleDateChange("from", "month", e.target.value),
                                months
                            )}
                            </Grid>
                            <Grid item xs={4}>
                            {renderDropdown(
                                "Year",
                                fromDate.year,
                                (e) => handleDateChange("from", "year", e.target.value),
                                years
                            )}
                            </Grid>
                        </Grid>

                        {/* To Date */}
                        <Typography variant="h6" mt={2} mb={1} className={classes.headingsForm}>
                            To Date
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                            {renderDropdown(
                                "Date",
                                toDate.date,
                                (e) => handleDateChange("to", "date", e.target.value),
                                dates
                            )}
                            </Grid>
                            <Grid item xs={4}>
                            {renderDropdown(
                                "Month",
                                toDate.month,
                                (e) => handleDateChange("to", "month", e.target.value),
                                months
                            )}
                            </Grid>
                            <Grid item xs={4}>
                            {renderDropdown(
                                "Year",
                                toDate.year,
                                (e) => handleDateChange("to", "year", e.target.value),
                                years
                            )}
                            </Grid>
                        </Grid>

                        {/* Till Date */}
                        <Box mt={2}>
                            <Typography variant="h6" mt={2} mb={1} className={classes.headingsForm}>
                                To Date
                            </Typography>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                    checked={tillDate}
                                    onChange={(e) => setTillDate(e.target.checked)}
                                    color="primary"
                                    />
                                }
                                label="Till Date"
                                style={{display: 'flex'}}
                            />
                        </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
            <Grid className={classes.submitButton}>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.buttonPay}
                >
                    Download Report
                </Button>
            </Grid>
        </Box>
    );
}

export default StatementCis;
