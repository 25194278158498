import {
    Box,
    Button,
    Container,
    Grid,
    makeStyles,
    Paper,
    Typography,
  } from "@material-ui/core";
  import { ARBITRATION, ARBITRATOR, IO_INPROGRESS, RESPONDENT_INPROGRESS } from "app/constants";
  import FormattedMessage from "features/common/TranslatedMessage";
  import React, { useEffect, useState } from "react";
  import { useHistory } from "react-router-dom";
  import { DocGenerator } from "utils";
  import { downloadLegalDoc, orixCaseResolved, reopenOrixCase, updateDispute } from "slices/disputeSlice";
  import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import { getClaimantDetails } from "features/singleDispute/ArbitratorHotstar/NewArbitratorSingleDispute";
import { KeyboardBackspace, PlayForWork } from "@material-ui/icons";
import instance from "api/globalaxios";
import { format } from "date-fns";
import { useBackground } from "contexts/BackgroundContext";
import { updateCase } from "slices/casesSlice";
import { useDispatch } from "react-redux";
import { useResumeFilling } from "contexts/ResumeFilling";
import { calc_days } from "./OrixOfficial/DisputesOrix";
import TimelineComponent from "Components/Timelines";
  
  const useStyles = makeStyles((theme) => ({
		root: {
			marginTop: "2rem",
			[theme.breakpoints.down("sm")]: {
				marginTop: "1.5rem",
			},
			[theme.breakpoints.down("xs")]: {
				marginTop: "1rem",
			},
		},
    text: {
      fontFamily: `"Inter", "sans-serif"`,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "24px",
    },
    blue: {
      fontFamily: `"Inter", "sans-serif"`,
      color: "#2F80ED",
      lineHeight: "24px",
    },
    fonts: {
      lineHeight: "24px",
      fontSize: "16px",
      fontFamily: `"Inter", "sans-serif"`,
    },
    item: {
      "&:hover": {
        background: "#EEEEEE",
      },
    },
    calenderBtn: {
      fontSize: "16px",
      fontWeight: "400",
      color: "#2F80ED",
      textTransform: "none",
      borderRadius: "2px",
      border: "1px solid #2F80ED",
      boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.24)",
      width: "100%",
      maxWidth: "100%",
      "&:hover": {
        background: "#2F80ED",
        color: "#FFFFFF",
        border: "1px solid #2F80ED",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
      },
			hov: {
				"&:hover": {
					color: "#333333",
				},
			},
			btntext: {
				color: theme.palette.primary.main,
				fontFamily: "Satoshi-Bold",
				textTransform: "none",
			},
    },
		claimantDetailsContainer: {
			display: "flex",
			flexDirection: "column",
			width: "100%",
			padding: "2rem",
			borderRadius: '4px',
			backgroundColor: '#f4f8ff',
		},
		claimantDetailsHeading: {
			marginBottom: "1rem",
			fontSize:'1.5rem',
			lineHeight:'1.8rem',
			color:'black'
		},
		claimantDetailsStatus: {
			borderRadius:'4px',
			padding:'8px',
			width:'fit-content',
			lineHeight:1,
		},
		claimantDetailsRow: {
			padding: '10px 0',
			borderBottom: '1px solid #d7dbe2',
			display: 'flex',
			gap: '10px',
			[theme.breakpoints.between("sm",'md')]: {
				display: 'block',
      },
		},
		disputeStatusContainer: {
			display: "flex",
			flexDirection: "column",
			width: "100%",
			padding: "0.8rem 1rem 0.8rem 3rem",
		},
		disputeStatusHeading: {
			marginBottom: "0.5rem",
			fontSize:'1.3rem',
			lineHeight:'1.8rem',
			color:'black'
		},
		button: {
			color: 'black',
			"&:disabled": {
				backgroundColor: '#0000001f',
				color: '#00000042',
			}
		}
  }));

	export async function getClaimantAnswerData(id) {
		return await instance({
			method: "get",
			url: "/claimantanswerdata/" + id + "/",
		});
	}

	export async function getCaseFiles(id) {
		return await instance({
			method: "get",
			url: "api/casefiles/" + id + "/",
		});
	}

	export async function getCaseTimeline(id) {
    return await instance({
      method: "get",
      url: `api/timeline/?id=${id}`,
    });
  }

  export default function DisputeDetailsOrix({
    activeDispute,
    activeCase,
    profile,
  }) {
    const classes = useStyles();
    const history = useHistory();
		const {setBackground} = useBackground();
    const dispatch = useDispatch();
		const { setResume } = useResumeFilling();

    const IS_ARBITRATOR = profile?.profile_type === ARBITRATOR;
		const IS_END_USER = profile?.profile_type === 'general';
		const IS_GRC = profile?.profile_type === 'vendor';
  
    const [loading, setLoading] = React.useState(true);
    const [claimant, setClaimant] = React.useState("");
		const [complainantData, setComplainantData] = React.useState([]);
		const [timeLines, setTimeLines] = React.useState('');
		const [timeline, setTimeLine] = useState([]);
		const [claimantAnswerData, setClaimantAnswerData] = useState({});

    React.useEffect(() => {
			setBackground('white');
      getClaimantDetails(activeDispute.id).then((res) => {
        for (let index = 0; index < res.data.length; index++) {
          const element = res.data[index];
          if (element.profile_type == "general") {
            setClaimant(element);
            setLoading(false);
            break;
          }
        }
      });
			getClaimantAnswerData(activeDispute?.id).then((res) => {
				const tempObject = res.data.reduce(
					(obj, item) => Object.assign(obj, { [item.question_text]: {question_id:item.question_id, question: item.question_text, answer:item.answer_value, ...item} }), {}
				);
				setClaimantAnswerData(tempObject);
			})
			getCaseFiles(activeDispute?.id).then((res)=>{
				const caseFiles = res.data;
				const tempObj = {
					created: activeDispute.created,
					gro: caseFiles.find(file => file.type === 'respondent')?.review_complete_date || caseFiles.find(file => file.type === 'nodal')?.review_complete_date || undefined,
					io: caseFiles.find(file => file.type === 'io')?.review_complete_date || undefined,
					md: caseFiles.find(file => file.type === 'md')?.review_complete_date || undefined,
					final_gro: caseFiles.find(file => file.type === 'final_respondent')?.review_complete_date || caseFiles.find(file => file.type === 'final_nodal')?.review_complete_date || undefined,
					final_response: caseFiles.find(file => file.type === 'final_nodal')?.review_complete_date ? 'final_nodal' : 'final_respondent',
				}
				setTimeLines(tempObj);
			})
			getCaseTimeline(activeDispute?.id)
      .then((res)=>{
        setTimeLine(res.data);
      })
    }, []);

		useEffect(() => {
			const tempArr = [
				{
					question: 'Ticket Number',
					answer: IS_ARBITRATOR || (activeDispute.gro_ticket_number && IS_END_USER) ? activeDispute.gro_ticket_number : activeDispute.grc_ticket_number,
				},
				{
					question: 'Email',
					answer: claimant?.user?.email,
				},
				{
					question: 'Complaint Category',
					get answer() {
						return claimantAnswerData[this.question]?.ground || '--';
					},
				},
				...(Object.hasOwn(claimantAnswerData, "Sub Complaint Category") ? 
					[{
						question: "Sub Complaint Category",
						get answer() {
							return claimantAnswerData[this.question]?.ground || '--';
						},
					}] : []
				),
				{
					question: 'Phone No.',
					answer: claimant?.phone_number,
				},
				{
					question: 'Loan No./ Booking No./ Schedule No./Disbursement No.',
					answer: activeDispute?.loan_or_booking_number,
				},
				{
					question: 'Request Ticket No.',
					answer: activeDispute?.request_ticket_number,
				},
				{
					question: 'State',
					answer: activeDispute?.state,
				},
				{
					question: 'City',
					answer: activeDispute?.city,
				},
			]
			setComplainantData(tempArr);
		}, [claimant, claimantAnswerData]);

    const download = (e) => {
      e.stopPropagation();
      DocGenerator(activeDispute.id, "claimant");
    };

		const handleDownloadResponse = () => {
			const profile_type = activeDispute?.gro_ticket_number ? 'gro' : 'respondent';
			DocGenerator(activeDispute.id, profile_type);
		}

		const handleDownloadResponseTimeline = (status, downloadKey) => {
			DocGenerator(activeDispute.id, status, downloadKey);
		}

		function ComplainantDetails({title, tag, complainantData}) {
			return (
				<Paper className={classes.claimantDetailsContainer}>
					<Typography className={classes.claimantDetailsHeading}>
						{title}
					</Typography>
					<Typography className={classes.claimantDetailsStatus} style={activeDispute.status === 'closed' ? {backgroundColor: '#caf4d1'} : {backgroundColor:'#ffefeb'}}>
						{tag}
					</Typography>
					{complainantData.map((item,index) => {
						return (
							<Box key={index} className={classes.claimantDetailsRow}>
								<Grid
									container
									justify="space-between"
									spacing={1}
								>
									<Grid item xs={6}>
										<Typography className={classes.boldText}>
											{item.question}
										</Typography>
									</Grid>
									<Grid item xs={6} style={{textAlign:'right'}}>
										<Typography>
											{item.answer}
										</Typography>
									</Grid>
								</Grid>
							</Box>
						)
					})}
					<Box className={classes.claimantDetailsRow} style={{border:'none',marginTop:'0.5rem'}}>
						<Button
							variant="outlined"
							fullWidth
							style={{color:'black', backgroundColor: 'white'}}
							startIcon={<PlayForWork/>}
              onClick={download}
						>
							Download Complaint
						</Button>
						<Box style={{height:'1rem'}}/>
						{IS_END_USER && (
							<Button
								className={classes.button}
								variant="outlined"
								fullWidth
								style={{backgroundColor: 'white'}}
								startIcon={<PlayForWork/>}
								onClick={handleDownloadResponse}
								disabled={activeDispute.status !== 'closed'}
							>
								Download Response
							</Button>
						)

						}
					</Box>
				</Paper>
			);
		}

		function TrackProgress() {
			return (
				<Paper className={classes.disputeStatusContainer}>
					<Typography className={classes.disputeStatusHeading}>
						{activeDispute.status === 'closed' ? 'Resolved' : 'In Progress'}
					</Typography>
					<Box style={{display:'flex',alignItems:'center',gap:'1rem', paddingBottom:'8px', borderBottom:'1px solid #dfdfdf'}}>
						<Typography style={{color:'#4b4e56',fontFamily: 'Satoshi-Bold'}}>
							Updated
						</Typography>
						<Typography>
							{format(new Date(activeDispute?.modified), "dd MMMM, y, h:mm a")}
						</Typography>
					</Box>
					<Box>
						<TimelineComponent
							timelineData={timeline}
							handleDownloadFile={handleDownloadResponseTimeline}
							isDownloadable={true}
						/>
					</Box>
				</Paper>
			);
		}

		const handleClickRecordResponse = () => {
			dispatch(
				updateCase({ proposed_flow: ARBITRATION, id: activeCase.id })
			)
			.then((response) => {
				let disputePayload = {
					id: activeDispute.id,
					status: IS_ARBITRATOR ? IO_INPROGRESS : RESPONDENT_INPROGRESS,
					flow_type: ARBITRATION,
				};
				dispatch(updateDispute(disputePayload));
				setResume(true);
			})
			.catch((err) => {
				console.log(err);
			});
		}

    if (loading) return <CircularCenteredLoader />;
  
    return (
      <Grid container justify="center" style={{backgroundColor: 'white', height:'100%'}}>
				<Grid item lg={11} md={10} sm={11} xs={11} className={classes.root}>
					<Button
						disableElevation
						disableFocusRipple
						disableRipple
						disableTouchRipple
						style={{ backgroundColor: "transparent", cursor: "default" }}
					>
						<Grid container spacing={1}>
							<Grid item>
								<Typography
									className={classes.btntext}
									style={{ color: "black", cursor: 'pointer' }}
									onClick={() => {
										history.goBack();
									}}
								>
									<KeyboardBackspace />
								</Typography>
							</Grid>
							<Grid item>
								<Typography
									className={`${classes.btntext} ${classes.hov}`}
									style={{ cursor: "pointer" }}
									onClick={() => {
										history.push("/");
									}}
								>
									Dashboard
								</Typography>
							</Grid>
							<Grid item>
								<Typography
									className={classes.btntext}
									style={{ color: "black" }}
								>
									/
								</Typography>
							</Grid>
							<Grid item>
								<Typography
									className={`${classes.btntext} ${classes.hov}`}
									style={{ cursor: "pointer" }}
									onClick={() => {
										history.push(`/disputes/${activeDispute.id}/`);
									}}
								>
									{activeDispute.name}
								</Typography>
							</Grid>
						</Grid>
					</Button>
					<Grid container style={{justifyContent:'space-between', gap:'2rem', alignItems:'center'}}>
            <Grid item xs>
              <Typography className="lineClamp" style={{fontFamily:'Satoshi-Bold'}}>{activeDispute?.description}</Typography>
            </Grid>
						<Grid item style={{display:'flex',gap:'1rem'}}>
							<Box style={{display:'flex',alignItems:'center',gap:'1rem'}}>
								<Typography>
									{format(new Date(activeDispute?.created), "dd MMMM, y, h:mm a")}
								</Typography>
								<Typography>
									({calc_days(activeDispute?.created)===0 ? 'Today' : calc_days(activeDispute?.created) + ' day ago'})
								</Typography>
							</Box>
							{IS_END_USER ? (
								(activeDispute?.openable && (calc_days(activeDispute?.created) <= 30)) && (
									<>
										<Button
											variant="contained"
											color="primary"
											style={{padding:'5px 15px'}}
											onClick={() => dispatch(reopenOrixCase(activeDispute.id))}
										>
											Reopen
										</Button>
									</>
								)
							) : (
								<>
									{((activeDispute.status === RESPONDENT_INPROGRESS && IS_GRC) || 
										(activeDispute.status === IO_INPROGRESS && IS_ARBITRATOR)) ? (
										<Button
											color="primary"
											style={{textDecoration:'underline'}}
											onClick={()=> setResume(true)}
										>
											Resume Response
										</Button>
									) : (
										<Button
											className={classes.button}
											variant="outlined"
											disabled={activeCase?.case_status === 'review_complete' || activeDispute.status === 'closed' || activeDispute.status === RESPONDENT_INPROGRESS || activeDispute.status === IO_INPROGRESS}
											onClick={handleClickRecordResponse}
										>
											{'Record Response'}
										</Button>
									)}
									<Button
										variant="contained"
										color="primary"
										style={{padding:'5px 15px'}}
										onClick={() => dispatch(orixCaseResolved(activeDispute.id))}
										disabled={activeDispute.status === 'closed' || (IS_GRC && activeDispute?.date_closed_by_grc)}
									>
										Close Grievance
									</Button>
								</>
							)}
						</Grid>
					</Grid>
					<Box style={{height:'2rem'}}/>
					<Grid container spacing={2}>
						<Grid item lg={6} md={6} sm={6} xs={12} style={{ display: "flex" }}>
							<ComplainantDetails 
								title={activeDispute.name.split("&")[0]}
								tag={activeDispute.status === 'closed' ? 'Complaint Resolved' : 'Complaint in progress'}
								complainantData={complainantData}
							/>
						</Grid>
						<Grid item lg={6} md={6} sm={6} xs={12} style={{ display: "flex" }}>
							<TrackProgress />
						</Grid>
					</Grid>
				</Grid>
      </Grid>
    );
  }
  