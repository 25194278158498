import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { UPLOAD_COMPLETE } from "app/constants";
import DebouncedButton from "features/common/DebouncedButton";
import FormattedMessage from "features/common/TranslatedMessage";
import { DropzoneDialog } from "material-ui-dropzone";
import mime from "mime-types";
import React from "react";
import { useDispatch } from "react-redux";
import { isHotStar, isMuthoot, isOrix, isStar } from "Routes/Routes";
import { updateCaseAnonymous } from "slices/casesSlice";
import { addUserFileAnonymous } from "slices/disputeSlice";

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: "3rem",
    color: "#E0E0E0",
  },
  heading: {
    fontSize: "32px",
    lineHeight: "36px",
    textAlign: "center",
  },
  firstheading: {
    margin: "2rem 0 0rem 0",
    fontSize: "16px",
    lineHeight: "36px",
    color: "#333333",
  },
  paper: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
  },
  box: {
    background: "#3F51B5",
    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.24)",
  },
  submit: {
    color: "#FFFFFF",
  },
  btn: {
    flexGrow: 1,
    background: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    // borderRadius: "4px",
    color: "#FFFFFF",
    height: "100%",
    textAlign: "left",
    textTransform: "none",
    "&:hover": {
      background: "#FFFFFF",
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
}));

const UploadDialog = ({ open, setOpen, addFile }) => {
  const classes = useStyles();
  return (
    <Box textAlign="center">
      <Paper
        className={classes.paper}
        style={{ padding: "3rem", maxWidth: "100%" }}
      >
        <Box>
          <IconButton
            className={classes.icon}
            aria-label="delete"
            onClick={() => {
              setOpen(true);
            }}
          >
            <AddCircleOutlineIcon className={classes.icon} />
          </IconButton>
        </Box>
      </Paper>

      <DropzoneDialog
        cancelButtonText={"cancel"}
        submitButtonText={"submit"}
        maxFileSize={104857600}
        open={open}
        onClose={() => setOpen(false)}
        onSave={(files) => {
          console.log("files", files);
          files.forEach((file) => {
            addFile(file);
          });
          setOpen(false);
        }}
        showPreviews={true}
        filesLimit={4}
        showFileNamesInPreview={true}
        acceptedFiles={[
          "image/jpeg",
          "image/png",
          "image/jpg",
          "application/msword",
          "application/pdf",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ]}
      />
    </Box>
  );
};

const renderFiles = (files, deleteFile) => {
  return files.length !== 0
    ? files.map((file, index) => {
        return (
          <Box key={index} textAlign="center">
            <Paper style={{ padding: "2rem", maxWidth: "100%" }}>
              <Typography variant="subtitle2">{file.name}</Typography>
              <IconButton
                aria-label="delete"
                color="primary"
                onClick={() => {
                  deleteFile(index);
                }}
              >
                <DeleteOutlineIcon />
              </IconButton>
            </Paper>
          </Box>
        );
      })
    : null;
};

export default function Upload({ activeCase, activeDispute, profile }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const addFile = (file) => {
    if (files.length < 4) {
      files.push(file);
      setFiles(files);
    }
  };
  const deleteFile = (index) => {
    let localFiles = [...files];
    localFiles.splice(index, 1);
    setFiles(localFiles);
  };

  const dispatch = useDispatch();

  async function uploadAndUpdateCase(files, activeCase, email) {
    const data = new FormData();
    files.forEach(async (file) => {
      data.append(file.name, file);
      const fileBody = {
        dispute: activeCase.dispute,
        path: 123,
        file,
        profile: profile.id,
        type: mime.lookup(file.name),
        name: file.name,
        size: file.size,
        is_attachment: true,
      };
      await dispatch(addUserFileAnonymous(fileBody, email));
    });
  }

  const onSubmit = async (e) => {
    setLoading(true);
    uploadAndUpdateCase(files, activeCase, profile.user.email).then(
      (response) => {
        setLoading(false);
        dispatch(
          updateCaseAnonymous(
            { id: activeCase.id, case_status: UPLOAD_COMPLETE },
            profile.user.email
          )
        );
      }
    );
  };

  return (
    <Grid container justify="center">
      <Grid item sm={12} xs={12} md={10}>
        <Box>
          <Grid container justify="center">
            <Grid item md={2} sm xs></Grid>
            <Grid item md={10} sm={12} xs={12}>
              <Box style={{ textAlign: "center" }}>
                <Typography className={classes.heading}>
                  <FormattedMessage id="pages.upload.impDoc" />
                </Typography>
              </Box>
              <Box height="1rem"></Box>
              <Grid container>
                <Grid item sm={12} xs={12} md={12}>
                  {(isHotStar || isStar || isMuthoot || isOrix) ? (
                    <Typography className={classes.firstheading}>
                      <FormattedMessage id="pages.upload.head2" />
                    </Typography>
                  ) : (
                    <Typography className={classes.firstheading}>
                      <FormattedMessage id="pages.upload.head1" />
                    </Typography>
                  )}

                  {renderFiles(files, deleteFile)}
                  {files.length !== 4 && (
                    <UploadDialog
                      open={open}
                      setOpen={setOpen}
                      addFile={addFile}
                    />
                  )}
                </Grid>
              </Grid>
              <Box height="1.5rem"></Box>
              <Grid container justify="center">
                <Grid item style={{ display: "flex" }}>
                  <DebouncedButton
                    variant="contained"
                    className={classes.btn}
                    size="large"
                    onClickHandler={onSubmit}
                    disabled={loading}
                    loading={loading}
                    // value={<FormattedMessage id="buttons.submit"/>  }
                    value={isMuthoot || isOrix ? 'Save Draft and Proceed' : <FormattedMessage id="buttons.submit" />}
                    debounceTime={1000}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}
