import { 
    Box, 
    Typography, 
    makeStyles, 
    Select, 
    Grid, 
    Button, 
    Paper, 
    TextField, 
    FormControl, 
    InputLabel, 
    MenuItem ,
    Divider,
    FormHelperText,
    ClickAwayListener,
    Popper,
    InputAdornment
  } from "@material-ui/core";
  import instance from "api/globalaxios";
  import React, {useEffect, useState} from "react";
  import "react-nice-dates/build/style.css";
  import MessageBox from "features/Tdsat/components/MessageBox";
  import BackBtnBreadcrumb from "../shared/BackBtnBreadcrumb";
import { useSnackbar } from "contexts/SnackbarContext";
import { LockOpenOutlined, LockOutlined, Visibility, VisibilityOff } from "@material-ui/icons";
  
  
  const useStyles = makeStyles((theme) => ({
    form: {
      maxWidth: 600,
      margin: '0 auto',
      padding: theme.spacing(3),
    },
    title: {
      marginBottom: theme.spacing(2.5),
      marginTop: theme.spacing(2.5),
    },
    paper: {
      padding: theme.spacing(4),
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      width: 600,
      marginBottom: theme.spacing(4),
      marginTop: theme.spacing(1),
      borderRadius: '10px'
    },
    button: {
        padding: theme.spacing(1),
        borderRadius: 12,
        fontSize: 22,
    },
    buttonPay: {
        padding: theme.spacing(1),
        borderRadius: 12,
        width: '30%',
        display: 'flex',
    },
    labelTitle: {
        display: 'flex',
        marginBottom: '8px',
        fontSize: '12px'
    },
    labelSubtitle: {
        display: 'flex',
        marginTop: '8px',
        fontSize: '11px',
        color: '#858D98',
        marginBottom: '32px'
    },
      amount: {
        fontSize: '18px',
      },
      titleCase: {
        color: '#000',
        fontSize: '16px',
        lineHeight: '22px',
        fontWeight: 700,
        display: 'flex'
      },
      nameTitle: {
        fontSize: '18px',
        lineHeight: '31.32px',
        fontWeight: 400,
        display: 'flex'
      },
      containerCaseInfo: {
        marginBottom: '18px'
      },
      userInfo: {
        fontSize: '18px',
        fontWeight: 400, 
        paddingLeft: '50px',
        paddingRight: '50px'
      },
      containerUser: {
        marginTop: '18px'
      },
      titleUserInfo: {
        display: 'flex',
        color: '#000',
        fontSize: '16px'
      },
      partyName: {
        fontSize: '18px',
        fontWeight: '400'
      },
      heading: {
        marginBottom: '20px',
        fontWeight: 'bold',
      },
      infoBox: {
        textAlign: 'left',
        marginBottom: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
      },
      label: {
        color: '#121212',
        fontSize: '0.875rem',
        fontWeight: 500,
        lineHeight: '1.3125rem',
        textAlign: 'left',
        marginBottom: '0.2rem',
      },
      value: {
        marginBottom: '10px',
      },
      button: {
        marginTop: '20px',
        backgroundColor: '#4a58e9',
        color: '#fff',
        '&:hover': {
          backgroundColor: '#3949ab',
        },
      },
      formControl: {
        margin: theme.spacing(2, 0),
        minWidth: 120,
        width: '100%',
      },
      judgeCompositionTitle: {
        width: '100%',
        color: '#4A3BFF',
        marginBottom: '8px',
        fontSize: '16px'
      },
      submitButton: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '24px'
      }
  }));
  
  export async function getProfileTypes(){
    return await instance({
      method: "get",
      url: `tdsat/profile_types/`
    });
  }
  
  export async function resetPassword(formData){
    return await instance({
      method: "put",
      url: `/api/user/reset-password/`,
      data: formData
    })
  }
  
  const ResetPasswordCreateUser = ({onBack, row}) => {
    const { showSnackbar } = useSnackbar();
    const [formData, setFormData] = useState({
        password: null,
        confirmPassword: '',
    });
    const [errors, setErrors] = useState({
      password: null,
      confirmPassword: '',
    });
    const [editPasswordResponse, setEditPasswordResponse]= useState();
    const [errorResponse, setErrorResponse]=useState();
    const [passwordType, setPasswordType] = useState("password");
  
    const classes = useStyles();
  
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
    };
  
    const validateForm = () => {
      let tempErrors = {};
      // if (!formData.password) tempErrors.password = 'This field is required';

      if (!formData.password) {
        tempErrors.password = "This field is required";
      } else if (!/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(formData.password)) {
        // Alphanumeric check and minimum 8 characters
        tempErrors.password = "Password must be alphanumeric and at least 8 characters long";
      }

      // Check if confirmPassword is empty
      if (!formData.confirmPassword) {
        tempErrors.confirmPassword = "This field is required";
      } else if (formData.password !== formData.confirmPassword) {
        tempErrors.confirmPassword = "Passwords do not match";
      }
      return tempErrors;
    };
  
    const handleResetPassoword= async(formData)=>{
      const formErrors = validateForm();
      console.log(formErrors,'formErrors')
      if (Object.keys(formErrors).length === 0) {  
        try {
          const response = await resetPassword({user_id: row?.id, new_password: formData?.password})
          setEditPasswordResponse(response?.data);
          setFormData({
            password: '',
            confirmPassword: '',
          });
          setErrors({
            password: '',
            confirmPassword: '',
          })
          showSnackbar("User Password Changed Successfully", "success");
        }catch(error){
          console.log(error,'error', error.response)
          setErrorResponse(error.response.data);
          showSnackbar(errorResponse, "error");
        }
      }else {
        setErrors(formErrors);
      }
    }

    const tooglePasswordType = () => {
      if (passwordType == "password") {
        setPasswordType("text");
      } else {
        setPasswordType("password");
      }
    };
  
    console.log(row, 'row')
    return(
        <Box component="form" className={classes.form}>
            <BackBtnBreadcrumb
              onBackClick={() => onBack()}
              breadcrumbs={[
                { label: 'Current Users', onClick: () => onBack() },
              ]}
              currentPath='Reset Password'
            />
            <Typography variant="h4" align="center" className={classes.title}>
                RESET PASSWORD
            </Typography>
            <Paper className={classes.paper}>
              <Grid item xs={12} style={{width: '100%'}}>
                <Grid container xs={12} style={{display: 'flex', justifyContent: 'space-between', marginBottom: '16px'}}>
                  <Typography className={classes.userInfo}>Username :</Typography>
                  <Typography className={classes.userInfo}>{row?.username} </Typography>
                </Grid>
                <Grid container xs={12} style={{display: 'flex', justifyContent: 'space-between', marginBottom: '16px'}}>
                  <Typography className={classes.userInfo}>Name :</Typography>
                  <Typography className={classes.userInfo}>{row?.first_name} </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography className={classes.label}>Reset Password</Typography>
                    <TextField
                        name="password"
                        fullWidth
                        required
                        type={passwordType}
                        margin="none"
                        variant="outlined"
                        value={formData.password}
                        onChange={handleInputChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              onClick={tooglePasswordType}
                              style={{ cursor: "pointer" }}
                            >
                              {passwordType == "password" ? (
                                <VisibilityOff/>
                              ) : (
                                <Visibility />
                              )}
                            </InputAdornment>
                          ),
                          startAdornment: (
                            <InputAdornment 
                              position="start"
                            >
                              <LockOpenOutlined/>
                            </InputAdornment>
                          )
                        }}
                    />
                    <FormHelperText>Password must be alphanumeric</FormHelperText>
                    {errors?.password && <FormHelperText error>{errors?.password}</FormHelperText>}
                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.label}>Confirm Password</Typography>
                    <TextField
                        name="confirmPassword"
                        fullWidth
                        type={passwordType}
                        required
                        margin="none"
                        variant="outlined"
                        value={formData.confirmPassword}
                        onChange={handleInputChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              onClick={tooglePasswordType}
                              style={{ cursor: "pointer" }}
                            >
                              {passwordType == "password" ? (
                                <VisibilityOff/>
                              ) : (
                                <Visibility />
                              )}
                            </InputAdornment>
                          ),
                          startAdornment: (
                            <InputAdornment 
                              position="start"
                            >
                              <LockOpenOutlined/>
                            </InputAdornment>
                          )
                        }}
                    />
                    {errors?.confirmPassword && <FormHelperText error>{errors?.confirmPassword}</FormHelperText>}
                </Grid>
              </Grid>
            </Paper>
            <Grid className={classes.submitButton}>
              <Button
                variant="contained"
                color="primary"
                className={classes.buttonPay}
                onClick={()=>handleResetPassoword(formData)}
              >
                Reset Password
              </Button>
            </Grid>
            {errorResponse && <MessageBox title={errorResponse?.error}/>}
            {editPasswordResponse && <MessageBox title="Password changed successfully" />}          
        </Box>
    )
  }
  
  export default ResetPasswordCreateUser;