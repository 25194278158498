// App.js (Main Component)
import React, { useState } from 'react';
import NoticeForm from '../Notice/NoticeForm';
import CreateUserTable from './CreateUserTable';
import BenchWiseHeadPriority from 'features/Tdsat/components/BenchWiseHeadPriority';
import BenchForm from '../Bench/BenchForm';
import CreateUserForm from './CreateUserForm';
import ResetPasswordCreateUser from './ResetPasswordCreateUser';

function CreateUser() {
  const [isCreatingUser, setIsCreatingUser] = useState(false);
  const [isResettingPassword, setIsResettingPassword] = useState(false); 
  const [selectedRow, setSelectedRow] = useState(null);

  const handleCreateNewUser = () => {
    setIsCreatingUser(true); 
    setIsResettingPassword(false); 
  };

  const handleBackToTable = () => {
    setIsCreatingUser(false); 
    setIsResettingPassword(false); 
  };

  const handleFormSubmit = () => {
    setIsCreatingUser(false);
    setIsResettingPassword(false); 
  };

  const handleShowResetPassword = (row) => {
    setIsResettingPassword(true);
    setSelectedRow(row);
    setIsCreatingUser(false); 
  };

  const handleBackFromResetPassword = () => {
    setIsResettingPassword(false);
  };


  return (
    <>
        {!isCreatingUser && !isResettingPassword && (
        <CreateUserTable 
            onCreateNewUser={handleCreateNewUser} 
            onShowResetPassword={handleShowResetPassword}
        />
        )}

        {isCreatingUser && (
            <CreateUserForm onBack={handleBackToTable} onSubmit={handleFormSubmit} />
        )}

        {isResettingPassword && (
            <ResetPasswordCreateUser onBack={handleBackFromResetPassword} row={selectedRow}/>
        )}
    </>
  );
}

export default CreateUser;
