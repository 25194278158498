import React, { useState } from "react";
import {
  Button,
  Popover,
  TextField,
  MenuItem,
  Box,
  Typography,
} from "@material-ui/core";

const AdvocateForm = ({ open, anchorEl, onClose, classes }) => {
  const [formData, setFormData] = useState({
    advocateCode: "",
    advocateType: "",
    advocateName: "",
    individualOrFirm: "",
    firmName: "",
    barRegistration: "",
    phoneNumber: "",
    alternatePhoneNumber: "",
    address: "",
    email: "",
  });
  const [errors, setErrors] = useState({});

  // Close Popover
  const handleClose = () => {
    onClose();
  };

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Clear errors for the field as the user types
    if (errors[name]) {
      setErrors({ ...errors, [name]: "" });
    }
  };

  // Validation
  const validateForm = () => {
    const newErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\d{10}$/;

    if (!formData.advocateName) newErrors.advocateName = "Advocate Name is required";
    if (!formData.barRegistration) newErrors.barRegistration = "Bar Registration is required";
    if (!formData.phoneNumber) {
      newErrors.phoneNumber = "Phone Number is required";
    } else if (!phoneRegex.test(formData.phoneNumber)) {
      newErrors.phoneNumber = "Phone Number must be 10 digits";
    }
    if (!formData.email) {
      newErrors.email = "Email Address is required";
    } else if (!emailRegex.test(formData.email)) {
      newErrors.email = "Invalid Email Address";
    }
    if (!formData.address) newErrors.address = "Correspondence Address is required";
    if (formData.individualOrFirm === "firm" && !formData.firmName) {
      newErrors.firmName = "Firm Name is required for firms";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      console.log("Form Submitted:", formData);
      handleClose();
    }
  };

  return (
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "left",
        }}
        PaperProps={{
            sx: { width: "60%" }, // Set width of the Popover
        }}
      >
        <Box className={classes.formAdvocate} style={{maxWidth: "400px",}}>
          <form onSubmit={handleSubmit}>
            {/* Advocate Type */}
            <TextField
                select
                fullWidth
                label="Advocate Type"
                name="advocateType"
                value={formData.advocateType}
                onChange={handleChange}
                margin="normal"
                variant="outlined"
            >
                <MenuItem value="">Select</MenuItem>
                <MenuItem value="advocate">Advocate</MenuItem>
                <MenuItem value="advocateCommissioner">
                    Advocate Commissioner
                </MenuItem>
            </TextField>

            {/* Advocate Name */}
            <TextField
              fullWidth
              label="Advocate Name"
              name="advocateName"
              value={formData.advocateName}
              onChange={handleChange}
              error={!!errors.advocateName}
              helperText={errors.advocateName}
              margin="normal"
              variant="outlined"
            />

            <TextField
              select
              fullWidth
              label="Individual/Firm"
              name="individualOrFirm"
              value={formData.individualOrFirm}
              onChange={handleChange}
              margin="normal"
              variant="outlined"
            >
              <MenuItem value="">Select</MenuItem>
              <MenuItem value="individual">Individual</MenuItem>
              <MenuItem value="firm">Firm</MenuItem>
            </TextField>

            {/* Firm Name (Conditional Display) */}
            {formData.individualOrFirm === "firm" && (
              <TextField
                fullWidth
                label="Firm Name"
                name="firmName"
                value={formData.firmName}
                onChange={handleChange}
                error={!!errors.firmName}
                helperText={errors.firmName}
                margin="normal"
                variant="outlined"
              />
            )}

            {/* Bar Registration */}
            <TextField
                fullWidth
                label="Bar Registration Number"
                name="barRegistration"
                value={formData.barRegistration}
                onChange={handleChange}
                error={!!errors.barRegistration}
                helperText={errors.barRegistration}
                margin="normal"
                variant="outlined"
            />

            {/* Phone Number */}
            <TextField
                fullWidth
                label="Phone Number"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber}
                margin="normal"
                variant="outlined"
            />

            {/* Alternate Phone Number */}
            <TextField
                fullWidth
                label="Alternate Phone Number"
                name="alternatePhoneNumber"
                value={formData.alternatePhoneNumber}
                onChange={handleChange}
                margin="normal"
                variant="outlined"
            />

            {/* Address */}
            <TextField
                fullWidth
                label="Correspondence Address"
                name="address"
                value={formData.address}
                onChange={handleChange}
                error={!!errors.address}
                helperText={errors.address}
                margin="normal"
                variant="outlined"
            />

            {/* Email */}
            <TextField
                fullWidth
                label="Email Address"
                name="email"
                value={formData.email}
                onChange={handleChange}
                error={!!errors.email}
                helperText={errors.email}
                margin="normal"
                variant="outlined"
            />

            {/* Submit Button */}
            <Box mt={2} display="flex" justifyContent="space-between">
              <Button variant="contained" color="primary" onClick={handleClose}>
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Save
              </Button>
            </Box>
          </form>
        </Box>
      </Popover>
  );
};

export default AdvocateForm;
