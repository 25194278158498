import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Select,
  MenuItem,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  makeStyles,
  Grid,
  FormControl,
} from "@material-ui/core";
import instance from "api/globalaxios";
import { useSnackbar } from "contexts/SnackbarContext";
import fileDownload from "js-file-download";
import Swal from "sweetalert2";
import MasterTable from "./MasterTable";
import { Print } from "@material-ui/icons";
import AdvocateForm from "./AdvocateForm";

export async function getMasterTypes(){
    return await instance({
      method: "get",
      url: `tdsat/master_types/`
    });
}

export async function getDetailsOfMasterTypeSelected(type){
    return await instance({
      method: "get",
      url: `tdsat/master/${type}/`
    });
}

export async function deleteRowOfMasterTable(type, id){
    return await instance({
      method: "delete",
      url: `tdsat/master/${type}/${id}`
    });
}

export async function createMasterObject(type, data){
    return await instance({
        method: "post",
        url: `tdsat/master/${type}/`,
        data
    });
}

export async function updateRowOfMasterTable(type, id, data){
    return await instance({
      method: "put",
      url: `tdsat/master/${type}/${id}/`,
      data
    });
}


const useStyles = makeStyles((theme) => ({
    form: {
      maxWidth: 800,
      margin: '0 auto',
      padding: theme.spacing(3),
      display:  'flex', 
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      marginBottom: theme.spacing(2.5),
      marginTop: theme.spacing(2.5),
    },
    paper: {
      padding: theme.spacing(4),
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      width: 600,
      marginBottom: theme.spacing(4),
      marginTop: theme.spacing(1),
      borderRadius: '10px'
    },
    paperTable: {
        padding: theme.spacing(4),
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: 800,
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(1),
        borderRadius: '10px'
    },
    label: {
        color: '#121212',
        fontSize: '0.875rem',
        fontWeight: 500,
        lineHeight: '1.3125rem',
        textAlign: 'left',
        marginBottom: '0.2rem',
      },
      submitButton: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '24px'
      },
      buttonPay: {
        padding: theme.spacing(1),
        borderRadius: 12,
        width: '30%',
        display: 'flex',
    },
    titleTop: {
        fontSize: '20px',
        fontWeight: 700,
    },
    tableRow: {
        display: 'flex',
        gap: '1rem',
        marginTop: '1rem',
        paddingBottom: '1rem',
        width: '100%',
        borderBottom: '1px solid #4B4E56',
    },
    tableBtn: {
        background: '#F4F8FF',
        width: '180px',
        borderRadius: '0px',
        color: 'black',
        fontSize: '14px',
        fontWeight: 700,
        cursor: 'pointer',
    },
    noBorders: {
        border: 'none',
        fontSize: '15px',
        
      },
      formAdvocate: {
        padding: '20px',
        display: 'flex',
        gap: '10px',
        width: '60%',
        // marginLeft: 'auto',
        // marginRight: 'auto',
      }
}));

const MasterCis = () => {
    const classes = useStyles();
    const [masterTypes, setMasterTypes] = useState([]); // Dropdown options
    const [selectedMasterType, setSelectedMasterType] = useState("");
    const [tableData, setTableData] = useState([]); // Table rows
    const [editIndex, setEditIndex] = useState(-1); // Track row being edited
    const [newRow, setNewRow] = useState({ code: "", shortName: "", name: "" }); // For adding a new row
    const [isLoading, setIsLoading] = useState(false);
    const { showSnackbar } = useSnackbar();
    const [selectedLabel, setSelectedLabel] = useState('');
    const [anchorEl, setAnchorEl] = useState(null); // State for Popover anchor

    // Function to handle button click and open the Popover
    const handleButtonClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    // Function to close the Popover
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);

    useEffect(() => {
        const fetchMasterTypes = async () => {
          try {
            const response = await getMasterTypes();
            setMasterTypes(response?.data);  
          } catch (error) {
            console.error("Error fetching Master Types", error);
          }
        };
    
        fetchMasterTypes();
    }, []);

     // Fetch data on Search button click
    const handleSearch = async () => {
        if (!selectedMasterType) {
            showSnackbar("Please select a Master Type", 'error')
            return;
        }

        setIsLoading(true);
        try {
            const response = await getDetailsOfMasterTypeSelected(selectedMasterType); 
            const data = response?.data?.results || [];

            if (data.length > 0) {
                setTableData(data);
            } else {
                setTableData([]);
                showSnackbar("No records found for selected Master Type", 'warning')
            }
        } catch (error) {
            console.error("Error fetching details:", error);
        } finally {
            setIsLoading(false); // Hide loader
        }
    };

    const handlePrintMasterReport = async () => {
        try {
            const response = await instance.get(`tdsat/master/${selectedMasterType}/generate`, {responseType: "blob"});
            fileDownload(
                response.data,
                `MasterReport-${selectedMasterType}.pdf`
            );
            Swal.fire({
                title: "Print Slip",
                text: "Report Downloaded Successfully",
                icon: "success"
            });
        }catch (error){
            console.error("There was an error fetching the case data!", error);
        }
    };
    
    const handleAddRow = async () => {
        if (!newRow.code || !newRow.name) {
            showSnackbar("Short Name and Name are required fields.", 'error');
            return;
        }
      
        try {
          // Prepare data for the API call
          const data = {
            code: newRow.code,
            name: newRow.name,
          };
      
          // Make the API call
          const response = await createMasterObject(selectedMasterType, data);
      
          if (response) {
            // Add the new row to the table data
            setTableData([...tableData, { id: response.data.id, ...newRow }]);
            setNewRow({ code: "", shortName: "", name: "" });
            showSnackbar("New row added successfully!", 'success');
          } else {
            console.error("Failed to add new row:", response);
            showSnackbar("Failed to add new row. Please try again.", 'error');
          }
        } catch (error) {
          console.error("Error adding new row:", error);
          showSnackbar("An error occurred while adding the new row.", 'error');
        }
    };      

    const handleEditRow = async (type, id, updatedData) => {
        try {
          const updatedRow = await updateRowOfMasterTable(type, id, updatedData);
          setTableData((prevData) =>
            prevData.map((row) => (row.id === id ? { ...row, ...updatedRow } : row))
          );
          showSnackbar(updatedRow?.data?.message, 'success');
          handleSearch();
        } catch (error) {
          console.error('Failed to update row:', error);
          if(error?.response?.status === 400){
            showSnackbar(error?.response?.data?.error, 'error');
          }
        }
    };

    // Handle deleting a row
    const handleDeleteRow = async (rowId) => {
        try {
            const response = await deleteRowOfMasterTable(selectedMasterType, rowId); 
      
            if (response) {
                const updatedData = tableData.filter((row) => row.id !== rowId);
                setTableData(updatedData);
                showSnackbar("Deleted row successfully",'success')
            } else {
                const errorData = await response.json();
                showSnackbar("Error Deleting row",'error')
                console.error("Failed to delete row:", errorData);
            }
        } catch (error) {
            console.error("Error deleting row:", error);
        }
      };
    
    const handleChangeMasterType = (e) => {
        const selectedValue = e.target.value;
        setSelectedMasterType(selectedValue);

        // Find the label for the selected value
        const selected = masterTypes.find((item) => item.value === selectedValue);
        setSelectedLabel(selected ? selected.label : '');
    };

    return (
        <Box component="form" className={classes.form}>
            <Typography variant="h4" align="center" className={classes.title}>
                MASTER
            </Typography>
            <Paper className={classes.paper}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl fullWidth margin="none" variant="outlined">
                            <Typography className={classes.label}>Master Type</Typography>
                            <Select
                                name="type"
                                value={selectedMasterType}
                                onChange={handleChangeMasterType}
                                displayEmpty
                                fullWidth
                            >
                                {masterTypes?.map((masterType, index) => (
                                    <MenuItem key={index} value={masterType.value}>
                                        {masterType.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid className={classes.submitButton}>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.buttonPay}
                        onClick={handleSearch}
                    >
                        Search
                    </Button>
            </Grid>
            </Paper>
            {tableData.length > 0 &&
            <>
                <Grid container alignItems="center" style={{justifyContent: 'space-between', marginTop:'80px'}}>
                    <Grid item>
                        <Typography className={classes.titleTop}>{selectedLabel} Master</Typography>
                    </Grid>

                    <Grid item>
                       {selectedMasterType ===  'advocates' &&
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleButtonClick}
                            style={{ marginTop: '20px', borderRadius: '8px', marginRight: '10px' }}
                        >
                            Add Advocate
                        </Button> }
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={handlePrintMasterReport}
                            style={{ marginTop: '20px', borderRadius: '8px' }}
                            endIcon={<Print/>}
                        >
                            Print
                        </Button> 
                    </Grid>
                </Grid>
            
                <Paper className={classes.paperTable}>
                    {selectedMasterType !==  'advocates' && 
                        <div className={classes.tableRow}>
                            <TextField
                                label={`${selectedLabel} Code`}
                                value={newRow.code}
                                onChange={(e) => setNewRow({ ...newRow, code: e.target.value })}
                                fullWidth
                                required
                                margin="none"
                                variant="outlined"
                            />
                            <TextField
                                label={`${selectedLabel} Name`}
                                value={newRow.name}
                                onChange={(e) => setNewRow({ ...newRow, name: e.target.value })}
                                fullWidth
                                required
                                margin="none"
                                variant="outlined"
                            />
                            <Button variant="contained" color="primary" onClick={handleAddRow} className={classes.buttonPay} endIcon={'+'}>
                                Add
                            </Button>
                        </div>
                    }
                    <MasterTable
                        data={tableData}
                        onAddRow={handleAddRow}
                        onEditRow={handleEditRow}
                        onDeleteRow={handleDeleteRow}
                        classes={classes}
                        selectedLabel={selectedLabel}
                        selectedMasterType={selectedMasterType}
                    />
                </Paper>
            </>
            }
            <AdvocateForm open={open} anchorEl={anchorEl} onClose={handleClose} classes={classes}/>
        </Box>
    );
};

export default MasterCis;
