import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Paper, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  section: {
    marginBottom: theme.spacing(3),
  },
  table: {
    minWidth: 650,
  },
  header: {
    marginBottom: theme.spacing(2),
  },
  titleHeading: {
    color: '#000',
    
  }
}));

const response = {
    "case_status": {
      "dairy_no_with_year": "46/2024",
      "case_type_case_no_year": "BA 1",
      "date_filing": "2024-09-16T11:09:51Z",
      "case_status": "pending"
    },
    "subject_category_name": "COST1",
    "subject_category_code": 1,
    "related_cases": [
      {
        "case_type_case_no_year": "BA 1",
        "date_filing": "2024-09-16T11:09:51Z",
        "open_status": "pending"
      }
    ],
    "case_current_purpose": {
      "bench": "single_bench",
      "court": 1,
      "next_listing_date": null,
      "next_listing_purpose": "For Hearing"
    },
    "claimant_details": [
      {
        "type": "Petitioner(s)",
        "full_name": "arjun tendulka",
        "father_or_husband_name": "ger",
        "phone_number": "erw",
        "alt_phone_number": "gerg",
        "correspondence_address": "ewrg",
        "email": "rgwe",
        "state": null
      },
      {
        "type": "appellant",
        "full_name": "sree",
        "father_or_husband_name": "kumar",
        "phone_number": "8098457813",
        "alt_phone_number": "7894561235",
        "correspondence_address": "chennai",
        "email": "surya@gmail.com",
        "state": null
      },
      {
        "type": "appellant",
        "full_name": "surya dev",
        "father_or_husband_name": "kumar",
        "phone_number": "8098457813",
        "alt_phone_number": "7894561235",
        "correspondence_address": "chennai",
        "email": "surya@gmail.com",
        "state": null
      },
      {
        "type": "applicant",
        "full_name": "sree",
        "father_or_husband_name": "kumar",
        "phone_number": "8098457813",
        "alt_phone_number": "7894561235",
        "correspondence_address": "chennai",
        "email": "surya@gmail.com",
        "state": null
      },
      {
        "type": "appellant",
        "full_name": "arjun",
        "father_or_husband_name": "arun",
        "phone_number": "7894564567",
        "alt_phone_number": null,
        "correspondence_address": "chennai",
        "email": "arun@gmail.com",
        "state": null
      },
      {
        "type": "applicant",
        "full_name": "arjun",
        "father_or_husband_name": "arun",
        "phone_number": "7894564567",
        "alt_phone_number": null,
        "correspondence_address": "chennai",
        "email": "arun@gmail.com",
        "state": null
      },
      {
        "type": "applicant",
        "full_name": "arjun",
        "father_or_husband_name": "arun",
        "phone_number": "7894564567",
        "alt_phone_number": null,
        "correspondence_address": "chennai",
        "email": "arun@gmail.com",
        "state": null
      },
      {
        "type": "applicant",
        "full_name": "arjun",
        "father_or_husband_name": "arun",
        "phone_number": "7894564567",
        "alt_phone_number": null,
        "correspondence_address": "chennai",
        "email": "arun@gmail.com",
        "state": null
      },
      {
        "type": "applicant",
        "full_name": "arjun",
        "father_or_husband_name": "arun",
        "phone_number": "7894564567",
        "alt_phone_number": null,
        "correspondence_address": "chennai",
        "email": "arun@gmail.com",
        "state": null
      },
      {
        "type": "applicant",
        "full_name": "arjun",
        "father_or_husband_name": "arun",
        "phone_number": "7894564567",
        "alt_phone_number": null,
        "correspondence_address": "chennai",
        "email": "arun@gmail.com",
        "state": null
      },
      {
        "type": "Petitioner(s)",
        "full_name": "rohit kumar",
        "father_or_husband_name": "sdcwd",
        "phone_number": "wdvw",
        "alt_phone_number": "evwevwev",
        "correspondence_address": "wevw",
        "email": "evwe",
        "state": "goa"
      },
      {
        "type": "appellant",
        "full_name": "surya",
        "father_or_husband_name": "kumar",
        "phone_number": "8098457813",
        "alt_phone_number": "7894561235",
        "correspondence_address": "chennai",
        "email": "surya@gmail.com",
        "state": null
      },
      {
        "type": "appellant",
        "full_name": "suryakumar yadav",
        "father_or_husband_name": "kumar",
        "phone_number": "8098457813",
        "alt_phone_number": "7894561235",
        "correspondence_address": "chennai",
        "email": "surya@gmail.com",
        "state": null
      },
      {
        "type": "appellant",
        "full_name": "arjun",
        "father_or_husband_name": "arunn",
        "phone_number": "7894564567",
        "alt_phone_number": null,
        "correspondence_address": "chennai",
        "email": "arun@gmail.com",
        "state": null
      },
      {
        "type": "petitioner",
        "full_name": "sree",
        "father_or_husband_name": "kumar",
        "phone_number": "8098457813",
        "alt_phone_number": "7894561235",
        "correspondence_address": "chennai",
        "email": "surya@gmail.com",
        "state": null
      },
      {
        "type": "petitioner",
        "full_name": "sree",
        "father_or_husband_name": "kumar",
        "phone_number": "8098457813",
        "alt_phone_number": "7894561235",
        "correspondence_address": "chennai",
        "email": "surya@gmail.com",
        "state": null
      },
      {
        "type": "petitioner",
        "full_name": "Karthikeyan",
        "father_or_husband_name": "surendran",
        "phone_number": "8098158006",
        "alt_phone_number": null,
        "correspondence_address": "Kanyakumari",
        "email": "carthikn52@gmail.com",
        "state": null
      },
      {
        "type": "applicant",
        "full_name": "muthu",
        "father_or_husband_name": "sathya",
        "phone_number": "7845126589",
        "alt_phone_number": null,
        "correspondence_address": "chennai",
        "email": "vapime2044@bsomek.com",
        "state": null
      },
      {
        "type": "applicant",
        "full_name": "atharva",
        "father_or_husband_name": "kumar",
        "phone_number": "8098158006",
        "alt_phone_number": null,
        "correspondence_address": "chennai",
        "email": "asfdasdf@gmail.com",
        "state": null
      },
      {
        "type": "applicant",
        "full_name": "ananth",
        "father_or_husband_name": "kumar",
        "phone_number": "08098158006",
        "alt_phone_number": null,
        "correspondence_address": "chennai",
        "email": "nithin111@gmail.com",
        "state": null
      },
      {
        "type": "applicant",
        "full_name": "fd",
        "father_or_husband_name": "sf",
        "phone_number": "sfd",
        "alt_phone_number": "fds",
        "correspondence_address": "fs",
        "email": "sdfds",
        "state": null
      },
      {
        "type": "applicant",
        "full_name": "sdg",
        "father_or_husband_name": "asdf",
        "phone_number": "7098158006",
        "alt_phone_number": null,
        "correspondence_address": "asdfasd",
        "email": "aasd@g.com",
        "state": null
      },
      {
        "type": "applicant",
        "full_name": "atharva",
        "father_or_husband_name": "kumar",
        "phone_number": "8098158006",
        "alt_phone_number": null,
        "correspondence_address": "chennai",
        "email": "asfdasdf@gmail.com",
        "state": null
      }
    ],
    "respondent_details": [
      {
        "type": "Respondent(s)",
        "full_name": "athar",
        "father_or_husband_name": "eqrgeq",
        "phone_number": "rgeqr",
        "alt_phone_number": "geqr",
        "correspondence_address": "erge",
        "email": "r",
        "state": null
      },
      {
        "type": "respondent",
        "full_name": "chiyan vikram",
        "father_or_husband_name": "dhuruv",
        "phone_number": "7845127854",
        "alt_phone_number": "7894561235",
        "correspondence_address": "Coimbatore",
        "email": "vikram@gmail.com",
        "state": null
      },
      {
        "type": "respondent",
        "full_name": "sree",
        "father_or_husband_name": "kumar",
        "phone_number": "8098457813",
        "alt_phone_number": "7894561235",
        "correspondence_address": "chennai",
        "email": "surya@gmail.com",
        "state": null
      },
      {
        "type": "respondent",
        "full_name": "deepak",
        "father_or_husband_name": "ajay",
        "phone_number": "7894564567",
        "alt_phone_number": null,
        "correspondence_address": "chennai",
        "email": "deepak@gmail.com",
        "state": null
      },
      {
        "type": "respondent",
        "full_name": "arjun",
        "father_or_husband_name": "arunachalam",
        "phone_number": "7894564567",
        "alt_phone_number": null,
        "correspondence_address": "chennai",
        "email": "arunchalam@gmail.com",
        "state": null
      },
      {
        "type": "respondent",
        "full_name": "athun",
        "father_or_husband_name": "koko",
        "phone_number": "8098457813",
        "alt_phone_number": "7894561235",
        "correspondence_address": "chennai",
        "email": "vikram@gmail.com",
        "state": null
      },
      {
        "type": "respondent",
        "full_name": "athun",
        "father_or_husband_name": "koko",
        "phone_number": "8098457813",
        "alt_phone_number": "7894561235",
        "correspondence_address": "chennai",
        "email": "vikram@gmail.com",
        "state": null
      },
      {
        "type": "respondent",
        "full_name": "arjun",
        "father_or_husband_name": "arunachalam",
        "phone_number": "7894564567",
        "alt_phone_number": null,
        "correspondence_address": "chennai",
        "email": "arunchalam@gmail.com",
        "state": null
      },
      {
        "type": "Respondent(s)",
        "full_name": "neeraj kumari",
        "father_or_husband_name": "vwv",
        "phone_number": "wevwe",
        "alt_phone_number": "vwev",
        "correspondence_address": "wevw",
        "email": "evw",
        "state": "kerala"
      }
    ],
    "case_proceeding_details": {
      "hearing_date": "2024-12-20",
      "purpose": "For Evidence",
      "status": "Pending",
      "order": "Adjourned",
      "remarks": "dfsad"
    },
    "document_details": []
}

const CaseStatus = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      {/* Case Status Section */}
      <div className={classes.section}>
        <Typography variant="h6" className={classes.header}>
          Case Status
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography>Diary No./Year: {response.case_status.dairy_no_with_year}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Case Type/Case No./Year: {response.case_status.case_type_case_no_year}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Date of Filing: {new Date(response.case_status.date_filing).toLocaleDateString()}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Case Status: {response.case_status.case_status}</Typography>
          </Grid>
        </Grid>
      </div>

      {/* Subject Category Section */}
      <div className={classes.section}>
        <Typography variant="h6" className={classes.header}>
          Subject Category
        </Typography>
        <Typography>
          Subject Code: {response.subject_category_code}
        </Typography>
        <Typography>
          Subject: {response.subject_category_name}
        </Typography>
      </div>

      {/* Related Cases Section */}
      <div className={classes.section}>
        <Typography variant="h6" className={classes.header}>
          Related Cases
        </Typography>
        <TableContainer component={Paper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Case Type/Case No./Year</TableCell>
                <TableCell>Date of Filing</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {response.related_cases.map((caseItem, index) => (
                <TableRow key={index}>
                  <TableCell>{caseItem.case_type_case_no_year}</TableCell>
                  <TableCell>{new Date(caseItem.date_filing).toLocaleDateString()}</TableCell>
                  <TableCell>{caseItem.open_status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      {/* Case Current Purpose Section */}
      <div className={classes.section}>
        <Typography variant="h6" className={classes.header}>
          Case Current Purpose
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography>Bench: {response.case_current_purpose.bench}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Court: {response.case_current_purpose.court}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>
              Next Listing Date: {response.case_current_purpose.next_listing_date ? new Date(response.case_current_purpose.next_listing_date).toLocaleDateString() : 'N/A'}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Next Listing Purpose: {response.case_current_purpose.next_listing_purpose}</Typography>
          </Grid>
        </Grid>
      </div>

      {/* Claimant Details Section */}
      <div className={classes.section}>
        <Typography variant="h6" className={classes.header}>
          Claimant Details
        </Typography>
        <TableContainer component={Paper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell>Full Name</TableCell>
                <TableCell>Father/Husband Name</TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell>Alternate Phone Number</TableCell>
                <TableCell>Correspondence Address</TableCell>
                <TableCell>Email</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {response.claimant_details.map((claimant, index) => (
                <TableRow key={index}>
                  <TableCell>{claimant.type}</TableCell>
                  <TableCell>{claimant.full_name}</TableCell>
                  <TableCell>{claimant.father_or_husband_name}</TableCell>
                  <TableCell>{claimant.phone_number}</TableCell>
                  <TableCell>{claimant.alt_phone_number || 'N/A'}</TableCell>
                  <TableCell>{claimant.correspondence_address}</TableCell>
                  <TableCell>{claimant.email}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      {/* Respondent Details Section */}
      <div className={classes.section}>
        <Typography variant="h6" className={classes.header}>
          Respondent Details
        </Typography>
        <TableContainer component={Paper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell>Full Name</TableCell>
                <TableCell>Father/Husband Name</TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell>Alternate Phone Number</TableCell>
                <TableCell>Correspondence Address</TableCell>
                <TableCell>Email</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {response.respondent_details.map((respondent, index) => (
                <TableRow key={index}>
                  <TableCell>{respondent.type}</TableCell>
                  <TableCell>{respondent.full_name}</TableCell>
                  <TableCell>{respondent.father_or_husband_name}</TableCell>
                  <TableCell>{respondent.phone_number}</TableCell>
                  <TableCell>{respondent.alt_phone_number || 'N/A'}</TableCell>
                  <TableCell>{respondent.correspondence_address}</TableCell>
                  <TableCell>{respondent.email}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      {/* Case Proceeding Details Section */}
      <div className={classes.section}>
        <Typography variant="h6" className={classes.header}>
          Case Proceeding Details
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography>Hearing Date: {new Date(response.case_proceeding_details.hearing_date).toLocaleDateString()}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Purpose: {response.case_proceeding_details.purpose}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Status: {response.case_proceeding_details.status}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Order: {response.case_proceeding_details.order}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>Remarks: {response.case_proceeding_details.remarks}</Typography>
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
};

export default CaseStatus;
