import React from 'react';
import TableContainer from './TableContainer';
import TableHeader from './TableHeader';
import TableBody from './TableBody';
import TableFooter from './TableFooter';
import { Table as MuiTable, TableContainer as MuiTableContainer } from '@material-ui/core';

const Table = ({
  data,
  columns,
  onRowClick,
  onSort,
  onFilter,
  loading,
  customStyles,
  rowsPerPageOptions = [10, 20, 50, 100],
  actions,
  pagination,
  handlePageChange,
}) => {

  return (
    <TableContainer>
      <MuiTableContainer>
        <MuiTable style={{marginLeft: 0, marginRight: 0, maxWidth: '100%' }}>
          <TableHeader
            columns={columns}
            onSort={onSort}
            customStyles={customStyles}
          />
          <TableBody
            data={data}
            columns={columns}
            onRowClick={onRowClick}
            actions={actions}
            loading={loading}
          />
          {pagination && (
            <TableFooter
              pagination={pagination}
              onPageChange={handlePageChange}
              rowsPerPageOptions={rowsPerPageOptions}
            />
          )}
        </MuiTable>
      </MuiTableContainer>
    </TableContainer>
  );
};

export default Table;