import { Snackbar } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { default as Alert, default as MuiAlert } from "@material-ui/lab/Alert";
import instance from "api/globalaxios";
import { CLAIMANT, INDIAN_STATES, STATES } from "app/constants";
import { useSnackbar } from "contexts/SnackbarContext";
import FormattedMessage from "features/common/TranslatedMessage";
import { introFormContext } from "features/intro/IntroContainerBeforeLogin";
import { useFormik } from "formik";
import debounce from "lodash/debounce";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  isCDR,
  isDigipub,
  isHotStar,
  isMuthoot,
  isNbf,
  isOrix,
  isStar,
  isViacom,
  isVikalp,
  isWebnyay,
} from "Routes/Routes";
import { createCaseAnonymous } from "slices/casesSlice";
import { getCompanyProductsAnonymous } from "slices/companySlice";
import { createDisputeAnonymous, getRealhost } from "slices/disputeSlice";
import { getCitiesByStateName } from "slices/userSlice";
import * as Yup from "yup";

function Aler(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  formContainer: {
    padding: "2rem",
  },
  hoverButton: {
    background: "#3F51B5",
    color: "#FFFFFF",
    border: "1px solid transparent",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#3F51B5",
      border: "1px solid #3F51B5",
    },
  },
}));

const DisputeSchema = Yup.object().shape({
  email: Yup.string()
    .required("Email field is required")
    .email("Invalid email"),
  // phone: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
  phone: Yup.string()
    .matches(/^[0-9]+$/, "Invalid phone number")
    .min(10, "Must be exactly 10 digits")
    .max(10, "Must be exactly 10 digits"),
  company: Yup.number()
    .required()
    .typeError("Please select a company from the dropdown."),
  company_product_type: Yup.string()
    .required()
    .typeError("Please select an option from the dropdown"),
  company_product: Yup.number()
    .required()
    .typeError("Please set the company product to proceed"),
});

const IntroForm = ({ companies }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [profile, setProfile] = useState();
  const [user, setUser] = useState();
  const [companyName, setCompanyName] = useState("");
  const caseLoading = useSelector((state) => state.cases.loading);
  const disputeLoading = useSelector((state) => state.disputes.loading);
  const { isVendor, setFormStep, setClaimantFormValues } =
    useContext(introFormContext);
  let history = useHistory();
  const { showSnackbar } = useSnackbar();

  const [companyProducts, setCompanyProducts] = React.useState([]);
  const [cities, setCities] = useState([]);
  const [complainCategory, setComplainCategory] = useState('');

  const grievanceTypes = [
    {
      id: 'grievance',
      value: 'Grievance',
    },
    {
      id: 'suggestion',
      value: 'Suggestion',
    },
  ]

  useEffect(() => {
    (isVendor || isOrix) &&
      getCompanyProductsAnonymous(profile?.company  || companies[0]?.id).then((response) => {
        setCompanyProducts(response);
        if(isOrix){
          formik.setFieldValue("company_product", response[0].id);
          formik.setFieldValue("company_product_type", response[0].id);
          formik.setFieldValue("company", companies[0]?.id);
        } 
      });
  }, [companies]);

  // React.useEffect(() => {
  //   if (companies.length == 1) {
  //     let company_id = companies[0]?.id
  //     getCompanyProductsAnonymous(company_id).then((response) => {
  //       setCompanyProducts(response);
  //       formik.setFieldValue("company", company_id, true);
  //     });
  //   }
  // }, [companies]);

  const [openSnackBar1, setOpenSnackBar1] = useState(false);
  const handleCloseSnackBar1 = () => {
    setOpenSnackBar1(false);
  };

  // debounced submit handler for general user as claimant
  const debouncedOnSubmit = debounce((values) => {
    if(isOrix && !values.existingCustomer){
      history.push('/annexure1');
      return;
    }
    if(isOrix && !values.helpDeskComplainRaised){
      history.push('/annexure2');
      return;
    }
    sessionStorage.setItem("email", values.email);
    let userT, profileT;
    dispatch(getUserProfile(values.email)).then((res) => {
      if (res.profiles.length < 1) {
        // alert("No profile found.");
        if (
          values.firstname == "" ||
          values.lastname == "" ||
          values.email == ""  ||
          (values.phone === "" && isMuthoot)
        ) {
          setOpenSnackBar1(true);
          // alert(
          //   "enter all the details as no profile is associated with provided email"
          // );
          return 0;
        } else {
          dispatch(createGuestSignUpAnonymous(values)).then((response) => {
            setProfile(response);
            setUser(response.user);
            profileT = response;
            userT = response.user;
            let name =
              userT.first_name +
              " " +
              userT.last_name +
              " & " +
              `${isMuthoot ? "Muthoot Finance" : companies[0]?.name}`;
            let description = name;
            let disputeBody = {
              name: name,
              company_product: values.company_product,
              description: description,
              grievance_type: isOrix ? 'grievance' : values.type,
              platform_type:
                isHotStar || isStar || isMuthoot || isWebnyay || isViacom || isDigipub || isOrix
                  ? "grievance"
                  : isCDR || isVikalp
                  ? "cdr"
                  : isNbf
                  ? "nbf"
                  : "rv",
              ...isOrix && {
                customer_type: complainCategory,
                loan_or_booking_number: values.loanNumber,
                request_ticket_number: values.ticketNumber,
                state: values.state,
                city: values.city,
              }
            };

            let createCaseBody = {
              summary: description,
              description: description,
              profile: profileT.id,
              type: CLAIMANT,
            };

            dispatch(createDisputeAnonymous(disputeBody, values.email))
              .then((response) => {
                createCaseBody.dispute = response;
                sessionStorage.setItem("disputeId", response);
                return dispatch(
                  createCaseAnonymous(createCaseBody, values.email)
                );
              })
              .then((response) => {
                let respondentCaseBody = {
                  summary: createCaseBody.summary,
                  description: createCaseBody.description,
                  type: "respondent",
                  dispute: createCaseBody.dispute,
                  region: values.region,
                };

                return dispatch(
                  createCaseAnonymous(respondentCaseBody, values.email)
                );
              })
              .then((response) => {
                history.push(`/dispute`);
              })
              // .then(() => {
              //   dispatch(testApi());
              //   dispatch(testApi2());
              // })
              .catch((err) => {
                //console.log(err);
              });
          }).catch((error)=>{
            console.log(error);
          })
        }
      } else {
        if (res.profiles.length > 1) {
          alert("Multiple profiles");
          return 0;
        } else {
          setProfile(res.profiles[0]);
          setUser(res.profiles[0].user);
          profileT = res.profiles[0];
          userT = res.profiles[0].user;
          let name =
            userT.first_name +
            " " +
            userT.last_name +
            " & " +
            `${isMuthoot ? "Muthoot Finance" : companies[0]?.name}`;

          let description = name;
          let disputeBody = {
            name: name,
            company_product: values.company_product,
            description: description,
            grievance_type: isOrix ? 'grievance' : values.type,
            platform_type:
              isHotStar || isStar || isMuthoot || isWebnyay || isViacom || isDigipub || isOrix
                ? "grievance"
                : isCDR || isVikalp
                ? "cdr"
                : isNbf
                ? "nbf"
                : "rv",
            ...isOrix && {
              customer_type: complainCategory,
              loan_or_booking_number: values.loanNumber,
              request_ticket_number: values.ticketNumber,
              state: values.state,
              city: values.city,
            }
          };

          let createCaseBody = {
            summary: description,
            description: description,
            profile: profileT.id,
            type: CLAIMANT,
          };

          dispatch(createDisputeAnonymous(disputeBody, values.email))
            .then((response) => {
              createCaseBody.dispute = response;
              sessionStorage.setItem("disputeId", response);
              return dispatch(
                createCaseAnonymous(createCaseBody, values.email)
              );
            })
            .then((response) => {
              let respondentCaseBody = {
                summary: createCaseBody.summary,
                description: createCaseBody.description,
                type: "respondent",
                dispute: createCaseBody.dispute,
                region: values.region,
              };

              return dispatch(
                createCaseAnonymous(respondentCaseBody, values.email)
              );
            })
            .then((response) => {
              history.push(`/dispute`);
            })
            // .then(() => {
            //   dispatch(testApi());
            //   dispatch(testApi2());
            // })
            .catch((err) => {
              //console.log(err);
            });
        }
      }
    });
  }, 1000);

  // handler for vendor as claimant
  const onProceed = (values) => {
    setClaimantFormValues({ ...values });
    setFormStep(2);
  };

  function getUserProfile(email) {
    return async (dispatch) => {
      try {
        const response = await instance.get(
          `/api/userappointmentprofilelistanonymous/${null}/${email}/${null}`
        );
        return response.data;
      } catch (error) {
        //console.log(error);
      }
    };
  }

  function createGuestSignUpAnonymous(values) {
    return async (dispatch) => {
      //   dispatch(setProgress());
      try {
        const response = await instance.post(
          "/anonymouscreateuser/",
          {
            phone_number: values.phone.trim() == "" ? null : values.phone,
            profile_type: "general",
            group: getRealhost(),
            user: {
              email: values.email,
              password: "Test@123",
              first_name: values.firstname,
              last_name: values.lastname,
              region: values.region,
            },
          },
          {
            headers: {
              "content-type": "application/json",
            },
          }
        );

        return response.data;
      } catch (error) {
        // console.log(error, error?.response?.data);
        if(error?.response?.data?.phone_number){
          showSnackbar('Profile with this phone number already exists.','error')
        } else {
          alert("ERROR");
        }
      }
    };
  }

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      company: isVendor ? profile.company : isOrix ? companies[0]?.id : null,
      company_product_type: null,
      company_product: null,
      region: null,
      type: null,
      city: null,
      state: null,
      existingCustomer: '',
      loanNumber: '',
      organizationName: '',
      helpDeskComplainRaised: '',
      ticketNumber: '',
      businessVertical: '',
    },
    onSubmit: isVendor ? onProceed : debouncedOnSubmit,
    validationSchema: DisputeSchema,
  });

  const booleanDropdown = [
    {
      label: "Yes",
      value: true,
    },
    {
      label: "No",
      value: false,
    },
  ];

  const categorizeInput = (input, businessVertical) => {
    const olfsPrefixes = [
      /^F\/DISB0\w{5}$/, // Matches F/DISB0XXXXX
      /^F\/TRF\w{5}$/,  // Matches F/TRFXXXXX
      /^DMFL020000\w{5}$/, // Matches DMFL020000XXXXX
      /^TRFL020000\w{5}$/, // Matches TRFL020000XXXXX
      /^EXFL020000\w{5}$/  // Matches EXFL020000XXXXX
    ];
  
    const ocilPrefixes = [
      /^DISB0\w{5}$/, // Matches DISB0XXXXX
      /^TROL020000\w{5}$/, // Matches TROL020000XXXXX
      /^DMOL020000\w{5}$/, // Matches DMOL020000XXXXX
      /^EXOL020000\w{5}$/  // Matches EXOL020000XXXXX
    ];
  
    for (const pattern of olfsPrefixes) {
      if (pattern.test(input) || businessVertical === 'loan_against_property'){
        return 'OLFS';
      }
    }
  
    for (const pattern of ocilPrefixes) {
      if (pattern.test(input) || businessVertical === 'rent_a_car') {
        return 'OCIL';
      }
    }
  
    return 'UNKNOWN';
  }

  const orixBusinessChoices = [
    {
      label: "Lease",
      value: 'lease',
    },
    {
      label: "Rent a car",
      value: 'rent_a_car',
    },
    {
      label: "Loan against property",
      value: 'loan_against_property',
    },
  ];

  return (
    <Box p="2rem 0">
      <Grid container spacing={3}>
        <Grid item xs></Grid>
        <Grid item xs={10} md={6}>
          <Paper className={classes.formContainer}>
            {" "}
            <Box>
              <Typography
                variant="h4"
                gutterBottom
                align="center"
              >
                <FormattedMessage id="pages.intro.introText" />
              </Typography>
              <Box height="1rem"></Box>
              <Box p="0rem 2rem">
                <form onSubmit={formik.handleSubmit} data-testid="intro-form">
                  <TextField
                    id="firstname"
                    name="firstname"
                    label={<FormattedMessage id="user.firstName" />}
                    variant="outlined"
                    fullWidth={true}
                    className={classes.item}
                    onChange={formik.handleChange}
                    value={formik.values.firstname}
                  />
                  {formik.errors.firstname ? (
                    <Alert severity="error">{formik.errors.firstname}</Alert>
                  ) : null}
                  <Box height="1rem"></Box>
                  <TextField
                    id="lastname"
                    name="lastname"
                    label={<FormattedMessage id="user.lastName" />}
                    variant="outlined"
                    fullWidth={true}
                    className={classes.item}
                    onChange={formik.handleChange}
                    value={formik.values.lastname}
                  />
                  {formik.errors.lastname ? (
                    <Alert severity="error">{formik.errors.lastname}</Alert>
                  ) : null}
                  <Box height="1rem"></Box>
                  <TextField
                    required
                    id="email"
                    name="email"
                    label={<FormattedMessage id="user.email" />}
                    variant="outlined"
                    fullWidth={true}
                    className={classes.item}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  {formik.errors.email && formik.touched.email ? (
                    <Alert severity="error">{formik.errors.email}</Alert>
                  ) : null}
                  <Box height="1rem"></Box>
                  <TextField
                    required={isMuthoot || isOrix}
                    name="phone"
                    id="phone"
                    label={<FormattedMessage id="user.phoneNumber" />}
                    variant="outlined"
                    // type="number"
                    fullWidth={true}
                    className={classes.item}
                    onChange={formik.handleChange}
                    value={formik.values.phones}
                    inputProps={{ maxLength: 10 }}
                  />
                  {formik.errors.phone && (
                    <Alert severity="error">{formik.errors.phone}</Alert>
                  )}

                  {isMuthoot && (
                    <>
                      <Box height="1rem"></Box>
                      <FormControl
                        fullWidth={true}
                        variant="outlined"
                        data-testid="region-select"
                      >
                        <InputLabel id="against">
                          <FormattedMessage id="user.region" /> 
                        </InputLabel>
                        <Select
                          required
                          id="region"
                          name="region"
                          value={formik.values.region}
                          onChange={formik.handleChange}
                          labelId="against"
                          label="Region"
                        >
                          {STATES.map((state, index) => (
                            <MenuItem key={index} value={state}>
                              {state}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </>
                  )}

                  <Box height="1rem"></Box>

                  {!isVendor && !isOrix && (
                    <Fragment>
                      <FormControl
                        fullWidth={true}
                        variant="outlined"
                        data-testid="respondent-select"
                      >
                        <InputLabel id="against">
                          {/* <FormattedMessage id="dispute.respondent" /> */}
                          {(isHotStar || isStar || isMuthoot) ? "Platform" : "Respondent"}
                        </InputLabel>
                        <Select
                          id="company"
                          name="company"
                          value={formik.values.company}
                          onChange={(e) => {
                            formik.setFieldValue("company_product", null);
                            formik.setFieldValue("company_product_type", null);
                            formik.handleChange(e);
                            companies.map((com) => {
                              if (com.id == e.target.value) {
                                setCompanyName(com.name);
                              }
                            });
                            setCompanyProducts([]);
                            getCompanyProductsAnonymous(e.target.value).then(
                              (response) => {
                                setCompanyProducts(response);
                              }
                            );
                          }}
                          labelId="against"
                          label="Respondent"
                        >
                          {companies.map((company, index) => (
                            <MenuItem key={company.id} value={company.id}>
                              {company.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {formik.errors.company ? (
                        <Alert severity="error">{formik.errors.company}</Alert>
                      ) : null}

                      <Box height="1rem"></Box>
                    </Fragment>
                  )}

                  {isMuthoot && (
                    <>
                      <FormControl
                        fullWidth={true}
                        variant="outlined"
                        data-testid="type-select"
                      >
                        <InputLabel id="type">
                          Type 
                        </InputLabel>
                        <Select
                          required
                          id="type"
                          name="type"
                          value={formik.values.type}
                          onChange={formik.handleChange}
                          labelId="type"
                          label="Type"
                        >
                          {grievanceTypes.map((type, index) => (
                            <MenuItem key={index} value={type.id}>
                              {type.value}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </>
                  )}

                  {!isOrix && (
                    <>
                      <Box height="1rem"></Box>

                      <FormControl
                        fullWidth={true}
                        variant="outlined"
                        disabled={companyProducts.length === 0}
                        data-testid="company-product-select"
                      >
                        <InputLabel id="against">
                          {" "}
                          {isMuthoot ? 'Complaint Category' : <FormattedMessage id="dispute.nature" />} 
                        </InputLabel>
                        <Select
                          id="company_product_type"
                          name="company_product_type"
                          value={formik.values.company_product_type}
                          onChange={(e) => {
                            formik.setFieldValue("company_product", null);
                            formik.handleChange(e);
                          }}
                          onBlur={formik.handleBlur}
                          labelId="company_product_type"
                          label={isMuthoot ? 'Complaint Category' : 'Company' }
                        >
                          {companyProducts
                            .map((item) => item.type)
                            .filter(
                              (value, index, self) => self.indexOf(value) === index
                            )
                            .map((type, index) => (
                              <MenuItem key={index} value={type}>
                                {type.replaceAll('_',' ')}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                      {formik.errors.company_product_type &&
                      formik.touched.company_product_type ? (
                        <Alert severity="error">
                          {formik.errors.company_product_type}
                        </Alert>
                      ) : null}

                      <Box height="1rem"></Box>

                      <FormControl
                        fullWidth={true}
                        variant="outlined"
                        disabled={companyProducts.length === 0}
                      >
                        <InputLabel id="against">
                          {(isHotStar || isStar) ? (
                            <FormattedMessage id="dispute.format" />
                          ) : (
                            isMuthoot ? 'Sub-catergory 1' : <FormattedMessage id="dispute.category" />
                          )}
                        </InputLabel>
                        <Select
                          id="company_product"
                          name="company_product"
                          value={formik.values.company_product}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          labelId="company_product"
                          label={isMuthoot ? 'Sub-catergory 1' : 'Category'}
                          data-testid="company-product-type-select"
                        >
                          {companyProducts
                            .filter(
                              (product) =>
                                product.type === formik.values.company_product_type
                            )
                            .map((product, index) => (
                              <MenuItem
                                key={product.id}
                                value={product.id}
                                data-testid={`company-product-type-item-${index}`}
                              >
                                {product.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                      {formik.errors.company_product &&
                      formik.touched.company_product ? (
                        <Alert severity="error">
                          {formik.errors.company_product}
                        </Alert>
                      ) : null}
                    </>
                  )}

                  {isOrix && (
                    <>
                      <TextField
                        required
                        fullWidth
                        variant='outlined'
                        margin="normal"
                        select
                        label='State'
                        name="state"
                        value={formik.values.state}
                        onChange={(e) => {
                          formik.handleChange(e);
                          setCities([]);
                          formik.setFieldValue("city", "");
                          getCitiesByStateName(e.target.value).then(
                            (response) => {
                              setCities(response);
                            }
                          );
                        }}
                      >
                        {INDIAN_STATES.map((state, index) => (
                          <MenuItem key={index} value={state}>
                            {state}
                          </MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        required
                        fullWidth
                        variant='outlined'
                        margin="normal"
                        select
                        label='City'
                        name="city"
                        value={formik.values.city}
                        onChange={formik.handleChange}
                        disabled={cities?.length === 0}
                      >
                        {cities?.map((city, index) => (
                          <MenuItem key={index} value={city}>
                            {city}
                          </MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        required
                        fullWidth
                        variant='outlined'
                        margin="normal"
                        select
                        label='Are you an existing customer of ORIX India?'
                        name="existingCustomer"
                        value={formik.values.existingCustomer}
                        onChange={formik.handleChange}
                      >
                        {booleanDropdown?.map((boolean, index) => (
                          <MenuItem key={index} value={boolean.value}>
                            {boolean.label}
                          </MenuItem>
                        ))}
                      </TextField>
                      {formik.values?.existingCustomer && (
                        <>
                          <TextField
                            required
                            fullWidth
                            variant='outlined'
                            margin="normal"
                            select
                            label='Which business vertical are you a customer of ORIX India?'
                            name="businessVertical"
                            value={formik.values.businessVertical}
                            onChange={formik.handleChange}
                          >
                            {orixBusinessChoices?.map((business, index) => (
                              <MenuItem key={index} value={business.value}>
                                {business.label}
                              </MenuItem>
                            ))}
                          </TextField>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            value={formik.values.loanNumber}
                            required
                            fullWidth
                            id="loanNumber"
                            label={formik.values.businessVertical === 'rent_a_car'  
                              ? 'Booking Reference No.' 
                              : formik.values.businessVertical === 'loan_against_property' 
                                ? 'Disbursement Number' 
                                : 'Loan Number/ Booking Number/ Schedule Number/Disbursement Number'
                            }
                            name="loanNumber"
                            autoComplete="loanNumber"
                            onBlur={formik.handleBlur}
                            onChange={(e)=>{
                              formik.handleChange(e);
                              setComplainCategory(categorizeInput(e.target.value, formik.values.businessVertical));
                            }}
                          />                   
                          {complainCategory === 'OLFS' && (
                            <>
                              <TextField
                                variant="outlined"
                                margin="normal"
                                value={formik.values.organizationName}
                                fullWidth
                                id="organizationName"
                                label={'Organization Name'}
                                name="organizationName"
                                autoComplete="organizationName"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                              />
                            </>
                          )}
                          <TextField
                            required
                            fullWidth
                            variant='outlined'
                            margin="normal"
                            select
                            label='Have you already raised your concern to ORIX INDIA customer service desk?'
                            name="helpDeskComplainRaised"
                            value={formik.values.helpDeskComplainRaised}
                            onChange={formik.handleChange}
                          >
                            {booleanDropdown?.map((boolean, index) => (
                              <MenuItem key={index} value={boolean.value}>
                                {boolean.label}
                              </MenuItem>
                            ))}
                          </TextField>
                          {formik.values?.helpDeskComplainRaised && (
                            <>
                              <TextField
                                required
                                variant="outlined"
                                margin="normal"
                                value={formik.values.ticketNumber}
                                fullWidth
                                id="ticketNumber"
                                label={'Request Ticket Number'}
                                name="ticketNumber"
                                autoComplete="ticketNumber"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                              />
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}

                  <Box height="1rem"></Box>
                  <Box display="flex" justifyContent="center">
                    {caseLoading || disputeLoading ? (
                      <CircularProgress />
                    ) : (
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        data-testid="submit-intro-claimant"
                        disabled={caseLoading || disputeLoading}
                        className={(isHotStar || isStar || isMuthoot || isOrix) && classes.hoverButton}
                      >
                        {isVendor ? (
                          <FormattedMessage id="buttons.proceed" />
                        ) : (
                          <FormattedMessage id="buttons.submit" />
                        )}
                      </Button>
                    )}
                  </Box>
                </form>
              </Box>
            </Box>
          </Paper>
          <Box height="4rem"></Box>
          {/* <Grid container justify="center">
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={(e) => {
                  history.push("/login");
                }}
              >
                Login
              </Button>
            </Grid>
          </Grid> */}
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            autoHideDuration={5000}
            open={openSnackBar1}
            onClose={handleCloseSnackBar1}
            message="Enter all the details as no profile is associated with provided email"
            // key={vertical + horizontal}
          >
            <Aler onClose={handleCloseSnackBar1} severity="error">
              {isMuthoot || isOrix ? 'Please enter all details.' : 'Enter all the details as no profile is associated with provided email'}
            </Aler>
          </Snackbar>
        </Grid>
        <Grid item xs />
      </Grid>
    </Box>
  );
};

export default IntroForm;
