import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getDisputes,
  getDisputesForPaginationOrix,
} from "slices/disputeSlice";
import DisputesOrixUser from "./DisputesOrixUser";
import { usePagination } from "hooks";

export const typeChoices = ["open", "closed"];

export default function DisputesContainerOrixUser() {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile, shallowEqual);
  const user = useSelector((state) => state.user.user);

  // const [currentPage, setCurrentPage] = useState(1);
  // const [totalPages, setTotalPages] = useState();

  // const { page, pageSize, handlePageChange } = usePagination(1);

  let sessionType = sessionStorage.getItem("orix-type");
  const [type, setType] = useState(sessionType ? sessionType : 'open');
  const [disputes, setDisputes] = useState([]);
  const [disputesLoading, setDisputesLoading] = useState(false);

  useEffect(()=>{
    reload(type);
  },[type])

  const reload = (newType = type) => {
    setDisputesLoading(true);
    // handlePageChange(1);
    dispatch(getDisputesForPaginationOrix(1, newType, null, null)).then((res) => {
      let count = res.count;
      let t = parseInt(count / 100);
      let r = count % 100;
      let total = t + (r > 0 ? 1 : 0);
      // setTotalPages(total);
      setDisputes(res.results);
      setDisputesLoading(false);
    });
  };

  const getUserDataAnotherPage = (pageNo) => {
    setDisputesLoading(true);
    dispatch(getDisputesForPaginationOrix(pageNo, type, null, null)).then((res) => {
      setDisputes(res.results);
      setDisputesLoading(false);
    });
  };

  let childProps = {
    disputes,
    profile,
    user,
    disputesLoading,
    setType,
    type,
    reload,
    // totalPages,
    // page,
    // handlePageChange,
    // pageSize,
    // getUserDataAnotherPage,
    profile
  };

  if (disputesLoading) return <CircularCenteredLoader />;
  return <DisputesOrixUser {...childProps} />;
}
