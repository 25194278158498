import React from 'react';
import { makeStyles, TextField, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: ({ backgroundColor }) => backgroundColor || '',
      '&:hover': {
        backgroundColor: ({ hoverBackgroundColor, backgroundColor }) => 
          hoverBackgroundColor || backgroundColor || '',
      },
      '& fieldset': {
        borderColor: ({ borderColor }) => borderColor || '',
      },
      '&:hover fieldset': {
        borderColor: ({ hoverBorderColor }) => hoverBorderColor || '',
      },
      '&.Mui-focused fieldset': {
        borderColor: ({ focusBorderColor }) => focusBorderColor || theme.palette.primary.main,
      },
    },
  },
}));

const SearchBar = ({
  placeholder = 'Search...',
  value,
  onChange,
  onSubmit,
  backgroundColor,
  hoverBackgroundColor,
  textColor = '#000000',
  iconColor = '#666',
  fontSize = 14,
  height = 42,
  borderRadius = 4,
  borderColor,
  hoverBorderColor,
  focusBorderColor,
  className,
  inputProps,
  showSearchIcon = false,
  searchIcon = <SearchIcon/>,
  TextFieldProps = {},
}) => {
  const classes = useStyles({ 
    backgroundColor, 
    hoverBackgroundColor,
    borderColor, 
    hoverBorderColor, 
    focusBorderColor
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    if (onSubmit) {
      onSubmit(value);
    }
  };

  const handleChange = (e) => {
    if (onChange) {
      onChange(e.target.value);
    }
  };

  return (
    <form onSubmit={handleSubmit} className={classes.root}>
      <TextField
        variant='outlined'
        className={className}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'search', ...inputProps }}
        InputProps={{
          endAdornment: showSearchIcon && (
            <InputAdornment position="end" onClick={handleSubmit}>
              {React.cloneElement(searchIcon, { style: { color: iconColor } })}
            </InputAdornment>
          ),
          style: {
            height,
            borderRadius,
          },
        }}
        {...TextFieldProps}
        sx={{
          '& .MuiOutlinedInput-input': {
            color: textColor,
            fontSize,
          },
          '& .MuiInputAdornment-root': {
            color: iconColor,
          },
          ...TextFieldProps.sx,
        }}
      />
    </form>
  );
};

export default SearchBar;