import React, { useEffect, useState } from 'react';
import {
  Table, TableBody, TableCell, Grid, Box, TableContainer, TableHead, TableRow, Button, Paper, IconButton, TablePagination,makeStyles,
  Typography, FormControl,
  Menu,
  MenuItem
} from '@material-ui/core';
import instance from 'api/globalaxios';
import CircularCenteredLoader from 'features/common/CircularCenteredLoader';
import Swal from 'sweetalert2';
import MoreVertIcon from "@material-ui/icons/MoreVert";

const useStyles = makeStyles((theme) => ({
  root: {
		padding: '1.5rem',
    width: '100%',
    boxSizing: 'border-box',
	},
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  searchField: {
    marginRight: theme.spacing(1),
    flexGrow: 1,
    borderRadius: '8px',
  },
  table: {
    minWidth: 650,
    width: '100%',
    maxWidth: '100%'
  },
  noDefect: {
    color: 'green',
  },
  pagination: {
    marginTop: theme.spacing(2),
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  datePickers: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    flexGrow: 1
  },
  noBorders: {
    border: 'none',
    fontSize: '15px',
    
  },
  noBordersTitle: {
    border: 'none',
    fontSize: '15px',
    fontFamily: 'Satoshi-Bold',
  },
  buttonPrint: {
    borderRadius: '8px',
    border: '1px solid var(--BG-Color-1, #AAAFB9)',
    background: '#fff'
  },
  tableRow: {
    '& tr': {
      marginBottom: '8px !important'  
    },
    '& th, & td': { 
      padding: '26px !important',  
    },
    border: '1px solid #E5E5E5',
    background: '#fff',
  },
  tabContainer: {
    marginBottom: theme.spacing(2),
  },
  titleTop: {
    fontSize: '20px',
    fontWeight: 700,
  }
}));

const CreateUserTable = ({onCreateNewUser, onShowResetPassword}) => {
  const classes = useStyles();
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false); 
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [countPagination, setCountPagination] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  useEffect(() => {
    fetchScrutinyReportData();
  },[])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchScrutinyReportData( newPage + 1); // Update data on page change
  };

  const handleDeleteUser = async (userId) => {
    try {
      const response = await instance.delete(`tdsat/users/?id=${userId}`);
      Swal.fire({
          title: "Delete",
          text: "Deleted User Successfully",
          icon: "success"
        });
      fetchScrutinyReportData();
    }catch (error){
        console.error("There was an error fetching the case data!", error);
    }
  };

  const fetchScrutinyReportData = async (page = 1) => {
    setLoading(true); 
    try {
    const params = {
        page: page > 1 ? page : 1,
    };
      const response = await instance.get('/tdsat/users/', {params});
      setFilteredData(response?.data?.results || []);
      setCountPagination(response?.data?.count);
    } catch (error) {
      console.error('There was an error fetching the case data!', error);
    }
    setLoading(false);
  };

  return (
    <div className={classes.root}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Grid container alignItems="center" style={{justifyContent: 'space-between'}}>
          <Grid item>
            <Typography className={classes.titleTop}>Current Users (Super Admin)</Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={onCreateNewUser}
              style={{ marginTop: '20px',  marginRight: '10px', borderRadius: '8px' }}
              endIcon={'+'}
            >
              Add User
            </Button>
          </Grid>
        </Grid>
      </Box>
      
      {loading ? (
        <div className={classes.loader}>
          <CircularCenteredLoader />
        </div>
      ) : (
        <>
          <TableContainer component={Paper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableRow}>
                <TableCell className={classes.noBordersTitle}>Sr. No.</TableCell>
                <TableCell className={classes.noBordersTitle}>Username</TableCell>
                <TableCell className={classes.noBordersTitle}>Location</TableCell>
                <TableCell className={classes.noBordersTitle}>Name</TableCell>
                <TableCell className={classes.noBordersTitle}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData?.length > 0 ? (
                filteredData.map((row, index) => (
                  <TableRow key={index} className={classes.tableRow}>
                  <TableCell className={classes.noBorders}>{(page) * rowsPerPage +(index + 1)}</TableCell>
                  <TableCell className={classes.noBorders}>{row?.username || '-'}</TableCell>
                  <TableCell className={classes.noBorders}>{row?.location || '-'}</TableCell>
                  <TableCell className={classes.noBorders}>{row?.first_name || '-'}</TableCell>
                  <TableCell className={classes.noBorders}>
                    <IconButton
                        aria-label="more"
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        getContentAnchorEl={null}
                        anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                        }}
                        transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                        }}
                    >
                    <MenuItem onClick={()=>onShowResetPassword(row)}>Reset Password</MenuItem>
                    <MenuItem onClick={()=>handleDeleteUser(row?.id)}>Delete</MenuItem>
                </Menu>
                  </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} className={classes.noBorders} align="center">
                    No User present
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={countPagination}
            page={page}
            onChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[]} 
            // onRowsPerPageChange={handleChangeRowsPerPage}
            className={classes.pagination}
          />
        </>
      )}
      
    </div>
  );
};

export default CreateUserTable;

