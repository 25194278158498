// TableHeader.js
import React from 'react';
import PropTypes from 'prop-types';
import { TableHead, TableRow, TableCell, TableSortLabel } from '@material-ui/core';

const TableHeader = ({ columns, onSort, sortField, sortOrder, customStyles }) => (
  <TableHead>
    <TableRow style={customStyles.rowStyle}>
      {columns.map((col) => (
        <TableCell
          key={col.field}
          align={col.align || 'left'}
          style={{ width: col.width, ...customStyles.cellStyle }}
        >
          {col.sortable ? (
            <TableSortLabel
              active={sortField === col.field}
              direction={sortField === col.field ? sortOrder : 'asc'}
              onClick={() => onSort(col.field, sortField === col.field && sortOrder === 'asc' ? 'desc' : 'asc')}
            >
              {col.headerName}
            </TableSortLabel>
          ) : (
            col.headerName
          )}
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);

TableHeader.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      headerName: PropTypes.string.isRequired,
      sortable: PropTypes.bool,
      align: PropTypes.oneOf(['left', 'center', 'right']),
      width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  onSort: PropTypes.func,
  sortField: PropTypes.string,
  sortOrder: PropTypes.oneOf(['asc', 'desc']),
  customStyles: PropTypes.shape({
    rowStyle: PropTypes.object,
    cellStyle: PropTypes.object,
  }),
};

TableHeader.defaultProps = {
  customStyles: {},
};

export default TableHeader;
