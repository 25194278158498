// TableBody.js
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TableBody as MuiTableBody, TableRow, TableCell, Checkbox, IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const TableBody = ({ data, columns, customRenderer, onRowClick, selectable = false, actions = null, customStyles }) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuRow, setMenuRow] = useState(null);

  const handleSelectRow = (row) => {
    setSelectedRows((prev) =>
      prev.includes(row) ? prev.filter((r) => r !== row) : [...prev, row]
    );
  };

  const handleMenuClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setMenuRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuRow(null);
  };

  return (
    <MuiTableBody>
      {data.map((row, rowIndex) => (
        <TableRow
          key={rowIndex}
          hover
          onClick={() => onRowClick && onRowClick(row)}
          style={{ cursor: onRowClick ? 'pointer' : 'default' }}
        >
          {selectable && (
            <TableCell padding="checkbox">
              <Checkbox
                checked={selectedRows.includes(row)}
                onChange={() => handleSelectRow(row)}
              />
            </TableCell>
          )}
          {columns.map((col) => (
            <TableCell key={col.field} align={col.align || 'left'} style={{ width: col.width, ...customStyles?.cellStyle }}>
              {col.customRenderer ? col.customRenderer(row[col.field], row) : row[col.field]}
            </TableCell>
          ))}
          {actions && (
            <TableCell align="right">
              <IconButton onClick={(e) => handleMenuClick(e, row)}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl) && menuRow === row}
                onClose={handleMenuClose}
              >
                {actions.map((action, index) => (
                  <MenuItem key={index} onClick={() => action.onClick(row)}>
                    {action.label}
                  </MenuItem>
                ))}
              </Menu>
            </TableCell>
          )}
        </TableRow>
      ))}
    </MuiTableBody>
  );
};

TableBody.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      customRenderer: PropTypes.func,
      align: PropTypes.oneOf(['left', 'center', 'right']),
    })
  ).isRequired,
  customRenderer: PropTypes.func,
  onRowClick: PropTypes.func,
  selectable: PropTypes.bool,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ),
};

TableBody.defaultProps = {
  selectable: false,
  actions: null,
};

export default TableBody;

