import { 
    Box, 
    Typography, 
    makeStyles, 
    Select, 
    Grid, 
    Button, 
    Paper, 
    TextField, 
    FormControl, 
    InputLabel, 
    MenuItem ,
    Divider,
    FormHelperText,
    ClickAwayListener,
    Popper
  } from "@material-ui/core";
  import instance from "api/globalaxios";
  import React, {useEffect, useState} from "react";
  import "react-nice-dates/build/style.css";
  import MessageBox from "features/Tdsat/components/MessageBox";
  import BackBtnBreadcrumb from "../shared/BackBtnBreadcrumb";
import { useSnackbar } from "contexts/SnackbarContext";

  const useStyles = makeStyles((theme) => ({
    form: {
      maxWidth: 600,
      margin: '0 auto',
      padding: theme.spacing(3),
    },
    title: {
      marginBottom: theme.spacing(2.5),
      marginTop: theme.spacing(2.5),
    },
    paper: {
      padding: theme.spacing(4),
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      width: 600,
      marginBottom: theme.spacing(4),
      marginTop: theme.spacing(1),
      borderRadius: '10px'
    },
    button: {
        padding: theme.spacing(1),
        borderRadius: 12,
        fontSize: 22,
    },
    buttonPay: {
        padding: theme.spacing(1),
        borderRadius: 12,
        width: '30%',
        display: 'flex',
    },
    labelTitle: {
        display: 'flex',
        marginBottom: '8px',
        fontSize: '12px'
    },
    labelSubtitle: {
        display: 'flex',
        marginTop: '8px',
        fontSize: '11px',
        color: '#858D98',
        marginBottom: '32px'
    },
      amount: {
        fontSize: '18px',
      },
      titleCase: {
        color: '#000',
        fontSize: '16px',
        lineHeight: '22px',
        fontWeight: 700,
        display: 'flex'
      },
      nameTitle: {
        fontSize: '18px',
        lineHeight: '31.32px',
        fontWeight: 400,
        display: 'flex'
      },
      containerCaseInfo: {
        marginBottom: '18px'
      },
      userInfo: {
        display: 'flex',
        flexDirection: 'column'
      },
      containerUser: {
        marginTop: '18px'
      },
      titleUserInfo: {
        display: 'flex',
        color: '#000',
        fontSize: '16px'
      },
      partyName: {
        fontSize: '18px',
        fontWeight: '400'
      },
      heading: {
        marginBottom: '20px',
        fontWeight: 'bold',
      },
      infoBox: {
        textAlign: 'left',
        marginBottom: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
      },
      label: {
        color: '#121212',
        fontSize: '0.875rem',
        fontWeight: 500,
        lineHeight: '1.3125rem',
        textAlign: 'left',
        marginBottom: '0.2rem',
      },
      value: {
        marginBottom: '10px',
      },
      button: {
        marginTop: '20px',
        backgroundColor: '#4a58e9',
        color: '#fff',
        '&:hover': {
          backgroundColor: '#3949ab',
        },
      },
      formControl: {
        margin: theme.spacing(2, 0),
        minWidth: 120,
        width: '100%',
      },
      judgeCompositionTitle: {
        width: '100%',
        color: '#4A3BFF',
        marginBottom: '8px',
        fontSize: '16px'
      },
      submitButton: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '24px'
      }
  }));
  
  export async function getProfileTypes(){
    return await instance({
      method: "get",
      url: `tdsat/profile_types/`
    });
  }
  
  export async function createUser(formData){
    return await instance({
      method: "post",
      url: `/tdsat/users/`,
      data: formData
    })
  }
  
  const CreateUserForm = ({onBack}) => {
    const [profileTypes, setProfileTypes] = useState([]);  
    const { showSnackbar } = useSnackbar();
    const [formData, setFormData] = useState({
        email: null,
        full_name: '',
        username: '',
        password: '',
        type: '',
        location: '',
    });
    const [errors, setErrors] = useState({
        email: null,
        full_name: '',
        username: '',
        password: '',
        type: '',
        location: '',
    });
    const [createUserResponse, setCreateUserResponse]= useState();
    const [errorResponse, setErrorResponse]=useState();
  
    const classes = useStyles();
  
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
    };
  
    useEffect(() => {
      const fetchProfileTypes = async () => {
        try {
          const response = await getProfileTypes();
          setProfileTypes(response?.data);  
        } catch (error) {
          console.error("Error fetching Profiles", error);
        }
      };
  
      fetchProfileTypes();
    }, []);
  
    const validateForm = () => {
      let tempErrors = {};
      if (!formData.type) tempErrors.type = 'This field is required';
      if (!formData.location) tempErrors.location = 'This field is required';
      if (!formData.full_name) tempErrors.full_name = 'This field is required';
      if (!formData.username) tempErrors.username = 'This field is required';
      if (!formData.email) tempErrors.email = 'This field is required';
      if (!formData.password) {
        tempErrors.password = "This field is required";
      } else if (!/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(formData.password)) {
        // Alphanumeric check and minimum 8 characters
        tempErrors.password = "Password must be alphanumeric and at least 8 characters long";
      }
      return tempErrors;
    };
  
    const handleSubmitUser= async(formData)=>{
      const formErrors = validateForm();
      if (Object.keys(formErrors).length === 0) {  
        try {
          const response = await createUser(formData)
          setCreateUserResponse(response?.data);
          setFormData({
            email: null,
            full_name: '',
            username: '',
            password: '',
            type: '',
            location: '',
          });
          setErrors({
            email: null,
            full_name: '',
            username: '',
            password: '',
            type: '',
            location: '',
          })
          showSnackbar("User Created Successfully", "success");
        }catch(error){
          console.log(error,'error', error.response)
          setErrorResponse(error.response.data);
          showSnackbar(errorResponse, "error");
        }
      }else {
        setErrors(formErrors);
      }
    }
  
    return(
        <Box component="form" className={classes.form}>
            <BackBtnBreadcrumb
              onBackClick={() => onBack()}
              breadcrumbs={[
                { label: 'Current Users', onClick: () => onBack() },
              ]}
              currentPath='Add user'
            />
            <Typography variant="h4" align="center" className={classes.title}>
                USER REGISTRATION
            </Typography>
            <Paper className={classes.paper}>
              <Grid container spacing={2}>
                  <Grid item xs={12}>
                      <FormControl fullWidth margin="none" variant="outlined">
                      <Typography className={classes.label}>Type Of Users</Typography>
                      <Select
                          name="type"
                          value={formData.type}
                          onChange={handleInputChange}
                      >
                          {profileTypes?.map((profile, index) => (
                              <MenuItem key={index} value={profile.value}>
                                {profile.label}
                              </MenuItem>
                          ))}
                      </Select>
                      {errors.type && <FormHelperText error>{errors.type}</FormHelperText>}
                      </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                        <Typography className={classes.label}>Location</Typography>
                        <TextField
                            name="location"
                            fullWidth
                            required
                            margin="none"
                            variant="outlined"
                            value={formData.location}
                            onChange={handleInputChange}
                        />
                        {errors?.location && <FormHelperText error>{errors?.location}</FormHelperText>}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.label}>Full Name</Typography>
                        <TextField
                            name="full_name"
                            fullWidth
                            required
                            margin="none"
                            variant="outlined"
                            value={formData.full_name}
                            onChange={handleInputChange}
                        />
                        {errors?.full_name && <FormHelperText error>{errors?.full_name}</FormHelperText>}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.label}>Username</Typography>
                        <TextField
                            name="username"
                            fullWidth
                            required
                            margin="none"
                            variant="outlined"
                            value={formData.username}
                            onChange={handleInputChange}
                        />
                        {errors?.username && <FormHelperText error>{errors?.username}</FormHelperText>}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.label}>Email</Typography>
                        <TextField
                            name="email"
                            fullWidth
                            required
                            margin="none"
                            variant="outlined"
                            value={formData.email}
                            onChange={handleInputChange}
                        />
                        {errors?.email && <FormHelperText error>{errors?.email}</FormHelperText>}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.label}>Password</Typography>
                        <TextField
                            name="password"
                            fullWidth
                            required
                            margin="none"
                            variant="outlined"
                            value={formData.password}
                            onChange={handleInputChange}
                        />
                        {errors?.password && <FormHelperText error>{errors?.password}</FormHelperText>}
                    </Grid>
              </Grid>
            </Paper>
            <Grid className={classes.submitButton}>
              <Button
                variant="contained"
                color="primary"
                className={classes.buttonPay}
                onClick={()=>handleSubmitUser(formData)}
              >
                Register User 
              </Button>
            </Grid>
            {errorResponse && <MessageBox title={errorResponse?.error}/>}
            {createUserResponse && <MessageBox title="User registered successfully" />}          
        </Box>
    )
  }
  
  export default CreateUserForm;