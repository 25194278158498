import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getDisputesCount,
  getDisputesForPaginationOrix,
} from "slices/disputeSlice";
import DisputesOrix from "./DisputesOrix";

export default function DisputesContainerOrix() {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile, shallowEqual);
  const user = useSelector((state) => state.user.user);

  const [count, setCount] = useState({ received: {}, registered: {} });
  const [countLoading, setCountLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();

  let sessionType = sessionStorage.getItem("muthoot-type");

  const [type, setType] = useState(sessionType ? sessionType : 'totalcases');
  const [disputes, setDisputes] = useState([]);
  const [disputesLoading, setDisputesLoading] = useState(false);
  const [region, setRegion] = useState(null);
  const [days, setDays] = useState(null);

  React.useEffect(() => {
    dispatch(getDisputesCount()).then((res) => {
      setCount(res);
      setCountLoading(false);
    });
    // reload()
  }, []);

  useEffect(()=>{
    reload(type,region,days);
  },[type,region,days])


  const reload = (newType = type, newregion = region, newdays = days) => {
    setDisputesLoading(true);
    setCurrentPage(1);
    dispatch(getDisputesForPaginationOrix(currentPage, newType, newregion, newdays)).then((res) => {
      let count = res.count;
      let t = parseInt(count / 100);
      let r = count % 100;
      let total = t + (r > 0 ? 1 : 0);
      setTotalPages(total);
      setDisputes(res.results);
      setDisputesLoading(false);
    });
  };

  const getUserDataAnotherPage = (pageNo) => {
    setDisputesLoading(true);
    dispatch(getDisputesForPaginationOrix(pageNo, type, region, days)).then((res) => {
      setDisputes(res.results);
      setDisputesLoading(false);
    });
  };

  let childProps = {
    disputes,
    profile,
    user,
    count,
    countLoading,
    disputesLoading,
    setType,
    type,
    reload,
    totalPages,
    currentPage,
    setCurrentPage,
    getUserDataAnotherPage,
    region,
    setRegion,
    days,
    setDays,
    profile
  };

  if (countLoading) return <CircularCenteredLoader />;
  return <DisputesOrix {...childProps} />;
}
