import DateFnsUtils from "@date-io/date-fns";
import {
  FormControl,
  Grid,
  InputAdornment,
  OutlinedInput,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import RadioGroup from "@material-ui/core/RadioGroup";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Typography from "@material-ui/core/Typography";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { format, parseISO } from "date-fns";
import { blockInvalidChar } from "features/TempTiacFormV2/ClaimantPage/questions";
import React, { useRef, useState } from "react";
import { isOrix } from "Routes/Routes";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(4),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  item: {
    marginTop: theme.spacing(1),
    width: "100%",
    // borderRadius: "0.3rem",
    resize: "none",
    padding: `${theme.spacing(1.5)}px ${theme.spacing(1)}px`,
    fontSize: "1rem",
    fontFamily: 'Satoshi-Medium',
    '&:focus-visible': {
      outlineColor: theme.palette.primary.main
    },
  },
  question: {
    fontSize: "16px",
    lineHeight: "24px",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  container: {
    width: 700,
  },
  number: {
    width: "3rem",
  },
}));

export const NumberFieldRange = ({ value, setValue, maxValue }) => {
  const classes = useStyles();
  const inpRef = useRef(null);

  const handleChangeTimeRange = (setValue, maxValue, inpRef) => {
    let val = inpRef.current.value;
    if (!val || val == "") val = 0;
    else val = parseInt(val);
    if (val >= 0 && val <= maxValue) {
      setValue(val);
      inpRef.current.value = val;
    }
  };

  return (
    <input
      className={classes.number}
      type="number"
      value={value}
      ref={inpRef}
      onChange={(e) => {
        handleChangeTimeRange(setValue, maxValue, inpRef);
      }}
      onKeyDown={blockInvalidChar}
    ></input>
  );
};

const Question = ({ question, answerValues, index, updateAnswerValues, onChange = ()=>{} }) => {
  const radioName = Math.floor(Math.random() * 1000000).toString();

  const classes = useStyles();
  let element;

  const [dropdown, setDropdown] = React.useState();
  const handleChangeDropDown = (e) => {
    updateAnswerValues(question.id, e.target.value);
    setDropdown(e.target.value)
  };

  const [booleanValue, setBooleanValue] = React.useState(
    answerValues.has(question.id)
      ? answerValues.get(question.id) === "true"
      : false
  );

  const [value, setValue] = React.useState(
    answerValues.has(question.id) ? answerValues.get(question.id) : ""
  );

  const [dateValue, setDataValue] = React.useState(
    answerValues.has(question.id) && question.type === "date"
      ? answerValues.get(question.id) !== null
        ? parseISO(answerValues.get(question.id))
        : null
      : setupInitialDate()
  );

  const [isOtherOption, setIsOtherOption] = useState(false);

  function setupInitialDate() {
    let date = new Date();
    if (question.type === "date") {
      setDateInAnswer(date);
    }
    return date;
  }

  function isValidDate(dateObject) {
    return new Date(dateObject).toString() !== "Invalid Date";
  }

  const handleDate = (event) => {
    let d = new Date(event);
    if (event && isValidDate(d)) {
      let value = event;
      setDataValue(value);
      setDateInAnswer(value);
    }
  };

  function setDateInAnswer(date) {
    updateAnswerValues(question.id, format(date, "yyyy-MM-dd"));
  }

  const [selectedDate, handleDateChange] = React.useState(new Date());

  const [timeValue, setTimeValue] = React.useState(
    answerValues.has(question.id) && question.type === "time"
      ? console.log("setAnswermm: ", answerValues.get(question.id))
      : setupInitialTime()
  );

  function setupInitialTime() {
    let time = new Date(2018, 11, 24, 0, 0, 0, 0);
    if (question.type === "time") {
      setTimeInAnswer(time);
    }
    return time;
  }

  const handleTime = (event) => {
    let value = event;
    setTimeValue(value);
    setTimeInAnswer(value);
  };

  function setTimeInAnswer(time) {
    updateAnswerValues(question.id, format(time, "HH:mm:ss"));
  }

  const handleChangeRadio = (event) => {
    if(event.target.value === 'Other – please specify'){
      setIsOtherOption(true);
    }else{
      setValue(event.target.value);
    }
    updateAnswerValues(question.id, event.target.value);
  };

  const handleChangeDropdown = (event) => {
    setValue(event.target.value);
    updateAnswerValues(question.id, event.target.value);
  };

  const handleChangeChecked = (event) => {
    let checked = event.target.checked;
    setBooleanValue(checked);
    updateAnswerValues(question.id, checked);
  };

  let numericRexEx = /^[0-9\b]+$/;

  const handleNumericValue = (event) => {
    let currentValue = event.target.value;
    if (currentValue.trim() === "" || currentValue.match(/^[0-9]+$/) != null) {
      setValue(currentValue);
      updateAnswerValues(question.id, currentValue);
    }
  };

  const [age, setAge] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const [hour, setHour] = React.useState(0);
  const [minutes, setMinutes] = React.useState(0);
  const [seconds, setSeconds] = React.useState(0);

  const handleHourChange = (e) => {
    setHour(e.target.value);
    updateAnswerValues(
      question.id,
      e.target.value + ":" + minutes + ":" + seconds
    );
  };
  const handleMinuteChange = (e) => {
    setMinutes(e.target.value);
    updateAnswerValues(question.id, hour + ":" + e.target.value + ":" + seconds);
  };
  const handleSecondChange = (e) => {
    setSeconds(e.target.value);
    updateAnswerValues(question.id, hour + ":" + minutes + ":" + e.target.value);
  };

  let prev_range = answerValues.has(question.id)
    ? answerValues.get(question.id)
      ? answerValues.get(question.id)
      : ""
    : "";
  let arr = prev_range.toString().split(/[: ]/);

  const [hour1, setHour1] = React.useState(arr[0] ? arr[0] : 0);
  const [minutes1, setMinutes1] = React.useState(arr[1] ? arr[1] : 0);
  const [seconds1, setSeconds1] = React.useState(arr[2] ? arr[2] : 0);

  const [hour2, setHour2] = React.useState(arr[4] ? arr[4] : 0);
  const [minutes2, setMinutes2] = React.useState(arr[5] ? arr[5] : 0);
  const [seconds2, setSeconds2] = React.useState(arr[6] ? arr[6] : 0);

  React.useEffect(() => {
    if (question.type === "time_range") {
      updateAnswerValues(
        question.id,
        hour1 +
          ":" +
          minutes1 +
          ":" +
          seconds1 +
          " to " +
          hour2 +
          ":" +
          minutes2 +
          ":" +
          seconds2
      );
    }
  }, [hour1, minutes1, seconds1, hour2, minutes2, seconds2]);

  if (question.type === "boolean") {
    element = (
      <>
        {" "}
        <Switch
          data-testid={`claimant-question-${index}`}
          checked={booleanValue}
          onChange={handleChangeChecked}
          color="primary"
          name="checked"
          inputProps={{ "aria-label": "primary checkbox" }}
        />
      </>
    );
  } else if (question.type === "simple") {
    element = (
      <>
        {" "}
        <TextareaAutosize
          data-testid={`claimant-question-${index}`}
          data-question-type="textarea"
          required
          label="Required"
          onChange={handleChangeRadio}
          className={classes.item}
          value={value}
          rowsMin={1}
          rowsMax={4}
          maxLength={question?.max_characters || 2000}
          style={isOrix ? {height: '15rem', overflow: 'auto'} : null}
        />
      </>
    );
  } else if (question.type === "number") {
    element = (
      <>
        <OutlinedInput
          data-testid={`claimant-question-${index}`}
          id="outlined-adornment-amount"
          name="outlined-adornment-amount"
          startAdornment={<InputAdornment position="start">₹</InputAdornment>}
          value={value}
          onChange={(e) => {
            handleNumericValue(e);
          }}
        />
      </>
    );
  } else if (question.type === "radio") {
    element = (
      <>
        <RadioGroup
          // data-testid={`claimant-question-${index}`}
          // aria-label="gender"
          // name="gender1"
          name={radioName}
          defaultValue={value}
          value={value}
          onChange={handleChangeRadio}
          style={{width:'100%'}}
          // className={classes.item}
        >
          <Box display="flex" flexDirection="column" alignItems="start">
            {JSON.parse(question.extra_args)["options"].map(
              (itemValue, index) => (
                <Box display="flex" style={{ margin: "0.5rem 0" }} key={index}>
                  <input
                    type="radio"
                    id={"" + itemValue + index}
                    name={radioName}
                    value={itemValue}
                    defaultChecked={itemValue == value}
                    onChange={handleChangeRadio}
                    style={{
                      transform: "scale(1.5)",
                      margin: "6px 3px 0px 6px",
                      cursor: "pointer",
                    }}
                  />
                  <Box width="0.6rem"></Box>
                  <Box alignItems="start" style={{ textAlign: "left" }}>
                    <Typography>
                      <label
                        for={"" + itemValue + index}
                        className={classes.question}
                        style={{
                          color: "#454545",
                          cursor: "pointer",
                        }}
                      >
                        {itemValue}
                      </label>
                    </Typography>
                  </Box>
                </Box>
              )
            )}
          </Box>
          {isOtherOption && (
            <>
              <TextareaAutosize
                color="primary"
                data-testid={`claimant-question-${index}`}
                data-question-type="textarea"
                required
                label="Required"
                onChange={handleChangeRadio}
                className={classes.item}
                style={{width:'97%'}}
                value={value}
                rowsMin={1}
                rowsMax={4}
                maxLength={2000}
              />
            </>
          )}
        </RadioGroup>
      </>
    );
  } else if (question.type === "dropdown") {
    element = (
      <>
        <FormControl
          fullWidth={true}
          variant="outlined"
          data-testid={question.id}
        >
          <InputLabel id="sadf">Select</InputLabel>
          <Select
            id={question.id}
            name={question.id}
            value={dropdown}
            onChange={(e) => {
              handleChangeDropDown(e);
              onChange(question,e);
            }}
            labelId={question.name}
            label={question.name}
            style={{ textAlign: "left" }}
          >
            {JSON.parse(question.extra_args)["options"].map((value, index) => {
              if (!value.deleted)
                return (
                  <MenuItem
                    key={value.id}
                    value={value}
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    {value}
                  </MenuItem>
                );
            })}
          </Select>
        </FormControl>
        {/* <InputLabel>Select</InputLabel>
        <Select
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={age}
          onChange={handleChangeDropdown}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select> */}
      </>
    );
  } else if (question.type === "date") {
    const today = new Date();
    const date = new Date();
    date.setDate(date.getDate() - 30);
    element = (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            margin="normal"
            minDate={date}
            maxDate={today}
            format="dd/MM/yyyy"
            value={dateValue}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            onChange={handleDate}
          />
        </MuiPickersUtilsProvider>
    );
  } else if (question.type === "time") {
    element = (
      <>
        <input
          className={classes.number}
          type="number"
          min="0"
          max="5"
          value={hour}
          onChange={handleHourChange}
        ></input>
        :
        <input
          className={classes.number}
          type="number"
          min="0"
          max="59"
          value={minutes}
          onChange={handleMinuteChange}
        ></input>
        :
        <input
          className={classes.number}
          type="number"
          min="0"
          max="59"
          value={seconds}
          onChange={handleSecondChange}
        ></input>
      </>
    );
  } else if (question.type === "time_range") {
    element = (
      <>
        <Grid container>
          <Grid
            item
            xs
            sm={6}
            style={{
              display: "flex",
              alignItems: "center",
              padding: "5px",
            }}
          >
            <Typography style={{ lineHeight: "24px", fontSize: "14px" }}>
              {"From: "}
            </Typography>
            <Box width="0.8rem"></Box>
            <NumberFieldRange value={hour1} setValue={setHour1} maxValue={5} />
            :
            <NumberFieldRange
              value={minutes1}
              setValue={setMinutes1}
              maxValue={59}
            />
            :
            <NumberFieldRange
              value={seconds1}
              setValue={setSeconds1}
              maxValue={59}
            />
          </Grid>
          <Grid
            item
            xs
            sm={6}
            style={{
              display: "flex",
              alignItems: "center",
              padding: "5px",
            }}
          >
            <Typography style={{ lineHeight: "24px", fontSize: "14px" }}>
              {"To: "}
            </Typography>
            <Box width="0.8rem"></Box>
            <NumberFieldRange value={hour2} setValue={setHour2} maxValue={5} />
            :
            <NumberFieldRange
              value={minutes2}
              setValue={setMinutes2}
              maxValue={59}
            />
            :
            <NumberFieldRange
              value={seconds2}
              setValue={setSeconds2}
              maxValue={59}
            />
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <Box className={index == 0 ? "introstep2" : null}>
      <Box textAlign="start">
        <Typography className={classes.question}>{question.name}{!question.optional && <span> *</span>}</Typography>
      </Box>
      <Box height="0.8rem"></Box>
      <Box display="flex" justifyContent="start">
        {element}
      </Box>
    </Box>
  );
};
export default Question;
