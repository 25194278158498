import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  IconButton,
} from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { Edit, Save } from "@material-ui/icons";

const MasterTable = ({ data, onAddRow, onEditRow, onDeleteRow, classes, selectedLabel, selectedMasterType }) => {
  const [editIndex, setEditIndex] = useState(-1); // Current row being edited
  const [editRow, setEditRow] = useState(null); // Temporary state for edited row

  const handleEditClick = (index) => {
    setEditIndex(index);
    setEditRow({ ...data[index] }); // Populate temporary state with row data
  };

  const handleFieldChange = (field, value) => {
    setEditRow((prev) => ({ ...prev, [field]: value }));
  };

  const handleSaveRow = () => {
    if (editRow && editRow.id) {
      const { id, code, name } = editRow;
      onEditRow(selectedMasterType, id, { code, name });
      setEditIndex(-1);
      setEditRow(null);
    }
  };

  return (
    <TableContainer component={Paper} sx={{ marginTop: 4 }}>
      <Table>
        {/* Table Head */}
        <TableHead>
          <TableRow>
            <TableCell className={classes.noBorders}>{`${selectedLabel} Code`}</TableCell>
            {selectedLabel === 'Case Type' && 
                <TableCell className={classes.noBorders}>{`${selectedLabel} Short Name`}</TableCell>
            }
            <TableCell className={classes.noBorders}>{`${selectedLabel} Name`}</TableCell>
            <TableCell className={classes.noBorders}>Modify</TableCell>
            <TableCell className={classes.noBorders}>Delete</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index}>
                <TableCell className={classes.noBorders}>
                    {row?.id}
                </TableCell>
                {selectedLabel === "Case Type" && (
                    <TableCell className={classes.noBorders}>
                        { editIndex === index ? (
                            <TextField
                                value={editRow.code}
                                onChange={(e) => handleFieldChange("code", e.target.value)}
                                variant="outlined"
                                size="small"
                            />
                            ) : (
                            row?.code
                        )}
                    </TableCell>
                )}
                <TableCell className={classes.noBorders}>
                    {editIndex === index ? (
                        <TextField
                            value={editRow.name}
                            onChange={(e) => handleFieldChange("name", e.target.value)}
                            variant="outlined"
                            size="small"
                        />
                        ) : (
                        row?.name
                    )}
                    {/* {row?.name} */}
                </TableCell>
              <TableCell className={classes.noBorders}>
                {editIndex === index ? (
                  <IconButton onClick={() => handleSaveRow(index)} className={classes.tableBtn}>
                    Save <Save />
                  </IconButton>
                ) : (
                  <IconButton  onClick={() => handleEditClick(index)} className={classes.tableBtn}>
                    Modify <Edit />
                  </IconButton>
                )}
              </TableCell>
              <TableCell className={classes.noBorders}>
                <IconButton onClick={() => onDeleteRow(row?.id)} className={classes.tableBtn}>
                  Delete <DeleteOutlineIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MasterTable;
