import { Box, Breadcrumbs, Link, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Report from "./Report";
import { KeyboardBackspace, NavigateNext } from "@material-ui/icons";
import DisposalReport from "./DisposalReport";
import RegisteredCasesReport from "./RegisteredCasesReport";
import CaseProceedingReport from "./CaseProceedingReport";
import PaymentReport from "./PaymentReport";
import CaseProceedingNotUpdatedReport from "./CaseProceedingNotUpdatedReport";
import PendingReport from "./PendingReport";
import CaseStatus from "./CaseStatus";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: '1.5rem',
    width: '100%',
    boxSizing: 'border-box',
	},
	entryForm: {
    width: '70%',
    margin: 'auto',
  },
  paper: {
    padding: `3rem 6rem`,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: theme.spacing(4),
    borderRadius: '0.625rem',
  },
  button: {
    padding: '0.7rem 2rem',
    borderRadius: '0.75rem',
    fontSize: '1.25rem',
    margin: '2rem',
  },
  formHeading: {
    fontSize: '1.375rem',
    fontFamily: 'Satoshi-Bold',
    color: theme.palette.primary.main,
    textAlign: 'left',
  },
  label: {
    color: '#121212',
    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: '1.3125rem',
    textAlign: 'left',
    marginBottom: '0.2rem',
  },
}));

export default function ReportContainer(){
	const classes = useStyles();
  const [searchReport, setSearchReport] = useState('report');
	const [subScreen, setSubScreen] = useState(null);

  useEffect(()=>{

  },[])

	const RenderReports = ({selected}) => {
		switch(selected){
			case 'report':
				return <Report classes={classes} REPORT_CHOICES={REPORT_CHOICES} handleClickSearch={handleClickSearch}/>
			case 'Case Status': 
				return <CaseStatus/>
			case 'Cause List Report':
				return <h1>Cause List Report</h1>
			case 'Disposal Report':
				return <DisposalReport/>
			case 'Case Proceeding Report':
				return <CaseProceedingReport/>
			case 'Payment Report':
				return <PaymentReport/>
			case 'Pending Report':
				return <PendingReport/>
			case 'Total Cases':
				return <RegisteredCasesReport/>
			case 'Proceeding Not Updated Report':
				return <CaseProceedingNotUpdatedReport/>
			case 'M.A. Status in Disposed Cases':
				return <h1>M.A. Status in Disposal Cases</h1>
			default:
				return <h1>Invalid Choice</h1>
		}
	};

	const REPORT_CHOICES = [
		'Case Status',
		'Cause List Report',
		'Disposal Report',
		'Case Proceeding Report',
		'Payment Report',
		'Pending Report',
		'Total Cases',
		'Proceeding Not Updated Report',
		'M.A. Status in Disposed Cases',
	];

	const handleClickSearch = (newValue) => {
		setSearchReport(newValue);
	};

	const handleGoBack = (newValue) => {
		setSearchReport(newValue);
	}

    return (
			<Box className={classes.root}>
				{(searchReport !== 'report') && (
					<Box style={{display:'flex',paddingBottom:'1.5rem',gap:'10px'}}>
						<Typography style={{cursor:'pointer'}} onClick={()=>{handleGoBack('report')}}>
							<KeyboardBackspace/>
						</Typography>
						<Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
							<Link color="inherit" style={{cursor:'pointer'}} onClick={()=>{handleGoBack('report')}}>
								Report
							</Link>
							<Typography color="textPrimary">{searchReport}</Typography>
						</Breadcrumbs>
					</Box>
				)}
				<RenderReports selected={searchReport} />
			</Box>
    )
}
