// TableFooter.js
import React from 'react';
import PropTypes from 'prop-types';
import { TableFooter as MuiTableFooter, TableRow, TablePagination } from '@material-ui/core';

const TableFooter = ({ pagination, onPageChange, rowsPerPageOptions }) => (
  <MuiTableFooter>
    <TableRow>
      <TablePagination
        rowsPerPageOptions={pagination.pageSize ? rowsPerPageOptions : []}
        count={pagination.total}
        rowsPerPage={pagination.pageSize}
        page={pagination.page}
        onPageChange={(e, newPage) => onPageChange(newPage)}
        onRowsPerPageChange={(e) => onPageChange(0, +e.target.value)}
      />
    </TableRow>
  </MuiTableFooter>
);

TableFooter.propTypes = {
  pagination: PropTypes.shape({
    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  }).isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default TableFooter;
