import instance, { instanceDPCGC, instanceHotstar } from "../api/globalaxios";
import fileDownload from "js-file-download";
import { chatUrl, chatInstance } from "api/globalaxios";
import { format, subYears } from "date-fns";
import { gethost } from "slices/disputeSlice";

export const downloadDoc = (url) => {
  console.log(url);
  window.open(`${chatUrl}${url}`, "_blank");
  // window.location.href = `${chatUrl}${url}`;
  // chatInstance({
  //   method: "get",
  //   url,
  //   // responseType: "blob",
  // }).then((response) => {
  //   console.log(response);
  //   fileDownload(response.data, `chat_doc.doc`);
  // });
};

export function ComplianceSROReportPdfGenerator(startDate, endDate) {
  instance({
    method: "get",
    url: "/generatecompliancesroreport/2/" + format(startDate, "dd-MM-yyyy") + "/" + format(endDate, "dd-MM-yyyy") + "?host=" + gethost(),
    responseType: "blob",
  }).then((response) => {
    fileDownload(
      response.data,
      `ComplianceReport_SRO_${format(startDate, "yyyy-MM-dd")}_to_${format(
        endDate,
        "yyyy-MM-dd"
      )}.pdf`
    );
  });
}

export function ComplianceSROReportDocxGenerator(startDate, endDate) {
  console.log(startDate);
  console.log(endDate);
  instance({
    method: "get",
    url: "/generatecompliancesroreport/1/" + format(startDate, "dd-MM-yyyy") + "/" + format(endDate, "dd-MM-yyyy") + "?host=" + gethost(),
    responseType: "blob",
  }).then((response) => {
    fileDownload(
      response.data,
      `ComplianceReport_SRO_${format(startDate, "yyyy-MM-dd")}_to_${format(
        endDate,
        "yyyy-MM-dd"
      )}.docx`
    );
  });
}

export function ComplianceCompanyReportPdfGenerator(startDate, endDate) {
  instance({
    method: "get",
    url:
      "/generatecompliancecompanyreport/2/" + format(startDate, "dd-MM-yyyy") + "/" + format(endDate, "dd-MM-yyyy") + "?host=" + gethost(),
    responseType: "blob",
  }).then((response) => {
    fileDownload(
      response.data,
      `ComplianceReport_company_${format(startDate, "yyyy-MM-dd")}_to_${format(
        endDate,
        "yyyy-MM-dd"
      )}.pdf`
    );
  });
}

export function ComplianceCompanyReportPdfGeneratorHotstar(startDate, endDate) {
  instance({
    method: "get",
    url:
      "/generatecompliancecompanyreporthotstar/2/" + format(startDate, "dd-MM-yyyy") + "/" + format(endDate, "dd-MM-yyyy") + "/",
    responseType: "blob",
  }).then((response) => {
    fileDownload(
      response.data,
      `ComplianceReport_company_${format(startDate, "yyyy-MM-dd")}_to_${format(
        endDate,
        "yyyy-MM-dd"
      )}.pdf`
    );
  });
}
export function ComplianceCompanyReportDocxGenerator(startDate, endDate) {
  console.log(startDate);
  console.log(endDate);
  instance({
    method: "get",
    url:
      "/generatecompliancecompanyreport/1/" + format(startDate, "dd-MM-yyyy") + "/" + format(endDate, "dd-MM-yyyy") + "?host=" + gethost(),
    responseType: "blob",
  }).then((response) => {
    fileDownload(
      response.data,
      `ComplianceReport_company_${format(startDate, "yyyy-MM-dd")}_to_${format(
        endDate,
        "yyyy-MM-dd"
      )}.docx`
    );
  });
}
export function ComplianceCompanyReportDocxGeneratorHotstar(startDate, endDate) {
  console.log(startDate);
  console.log(endDate);
  instance({
    method: "get",
    url:
      "/generatecompliancecompanyreporthotstar/1/" + format(startDate, "dd-MM-yyyy") + "/" + format(endDate, "dd-MM-yyyy") + "/",
    responseType: "blob",
  }).then((response) => {
    fileDownload(
      response.data,
      `ComplianceReport_company_${format(startDate, "yyyy-MM-dd")}_to_${format(
        endDate,
        "yyyy-MM-dd"
      )}.docx`
    );
  });
}

export function ComplianceReportPdfGenerator(startDate, endDate) {
  instance({
    method: "get",
    url: "/generatecompliancereport/2/" + format(startDate, "dd-MM-yyyy") + "/" + format(endDate, "dd-MM-yyyy") + "?host=" + gethost(),
    responseType: "blob",
  }).then((response) => {
    fileDownload(
      response.data,
      `ComplianceReport_${format(startDate, "yyyy-MM-dd")}_to_${format(
        endDate,
        "yyyy-MM-dd"
      )}.pdf`
    );
  });
}

export function ComplianceReportDocxGenerator(startDate, endDate) {
  console.log(startDate);
  console.log(endDate);
  instance({
    method: "get",
    url: "/generatecompliancereport/1/" + format(startDate, "dd-MM-yyyy") + "/" + format(endDate, "dd-MM-yyyy") + "?host=" + gethost(),
    responseType: "blob",
  }).then((response) => {
    fileDownload(
      response.data,
      `ComplianceReport_${format(startDate, "yyyy-MM-dd")}_to_${format(
        endDate,
        "yyyy-MM-dd"
      )}.docx`
    );
  });
}

export function DocGeneratorAnonymous(dispute_id, profile_type, email) {
  instance({
    method: "get",
    url: "/anonymousgeneratedoc/" + dispute_id + "/" + profile_type + "/",
    // url: "/generatedoc/" + dispute_id + "/" + profile_type + "/",
    params: {
      email: email,
    },
    responseType: "blob",
  }).then((response) => {
    fileDownload(response.data, `dispute_${dispute_id}_${profile_type}.pdf`);
  });
}

export function DocGenerator(dispute_id, profile_type, download_key) {
  instance({
    method: "get",
    url: "/generatedoc/" + dispute_id + "/" + profile_type + "/" + (download_key || ''),
    responseType: "blob",
  }).then((response) => {
    fileDownload(response.data, `dispute_${dispute_id}_${profile_type}.pdf`);
  });
}
export function HotstarDocGenerator(dispute_id, profile_type) {
  instanceHotstar({
    method: "get",
    url: "/generatedoc/" + dispute_id + "/" + profile_type + "/",
    responseType: "blob",
  }).then((response) => {
    fileDownload(response.data, `dispute_${dispute_id}_${profile_type}.pdf`);
  });
}
export function DPCGCDocGenerator(dispute_id, profile_type) {
  instanceDPCGC({
    method: "get",
    url: "/generatedoc/" + dispute_id + "/" + profile_type + "/",
    responseType: "blob",
  }).then((response) => {
    fileDownload(response.data, `dispute_${dispute_id}_${profile_type}.pdf`);
  });
}

export function LegalDocGet(dispute_id) {
  instance({
    method: "get",
    url: "/renderdoc/" + dispute_id + "/",
    responseType: "blob",
  }).then((response) => {
    fileDownload(response.data, `dispute_${dispute_id}.doc`);
  });
}

export function getOne2OneMeeting(id, profile_type) {
  if (profile_type === "claimant" || profile_type === "respondent") {
    return startMeeting(id + "_one2one");
  }
  return null;
}

export function getCommonMeeting(id) {
  return startMeeting(id + "_common");
}

function startMeeting(roomName) {
  return "webnyay_" + roomName;
}

export function Level1ReportGenerator(startDate, endDate) {
  
  instance({
    method: "get",
    url: "/generatelevel1report/1/" + format(startDate, "dd-MM-yyyy") + "/" + format(endDate, "dd-MM-yyyy") +"?host=" + gethost(),
    responseType: "blob",
  }).then((response) => {
    fileDownload(
      response.data,
      `Level1Report_${format(startDate, "yyyy-MM-dd")}_to_${format(
        endDate,
        "yyyy-MM-dd"
      )}.docx`
    );
  });
}
export function Level1ReportGeneratorHotstar(startDate, endDate) {
  
  instance({
    method: "get",
    url: "/generatelevel1reporthotstar/1/" + format(startDate, "dd-MM-yyyy") + "/" + format(endDate, "dd-MM-yyyy") + "/",
    responseType: "blob",
  }).then((response) => {
    fileDownload(
      response.data,
      `Level1Report_${format(startDate, "yyyy-MM-dd")}_to_${format(
        endDate,
        "yyyy-MM-dd"
      )}.docx`
    );
  });
}
export function Level3ReportGenerator(startDate, endDate) {
  instance({
    method: "get",
    url: "/generatelevel3report/1/" + format(startDate, "dd-MM-yyyy") + "/" + format(endDate, "dd-MM-yyyy") + "/",
    responseType: "blob",
  }).then((response) => {
    fileDownload(
      response.data,
      `Level1Report_${format(startDate, "yyyy-MM-dd")}_to_${format(
        endDate,
        "yyyy-MM-dd"
      )}.docx`
    );
  });
}

export function Level1ReportGeneratorPdf(startDate, endDate) {
  instance({
    method: "get",
    url: "/generatelevel1report/2/" + format(startDate, "dd-MM-yyyy") + "/" + format(endDate, "dd-MM-yyyy") + "/",
    responseType: "blob",
  }).then((response) => {
    fileDownload(
      response.data,
      `Level1Report_${format(startDate, "yyyy-MM-dd")}_to_${format(
        endDate,
        "yyyy-MM-dd"
      )}.pdf`
    );
  });
}

export function Level2ReportGenerator(startDate, endDate) {
  instance({
    method: "get",
    url: "/generatelevel2report/1/" + format(startDate, "dd-MM-yyyy") + "/" + format(endDate, "dd-MM-yyyy") + "/",
    responseType: "blob",
  }).then((response) => {
    fileDownload(
      response.data,
      `Level2Report_${format(startDate, "yyyy-MM-dd")}_to_${format(
        endDate,
        "yyyy-MM-dd"
      )}.docx`
    );
  });
}




export function PublishComplianceReport(startDate, endDate) {
  instance({
    method: "get",
    url: "/publishcomplaincereport/2/" + format(startDate, "dd-MM-yyyy") + "/" + format(endDate, "dd-MM-yyyy") + "/",
    responseType: "blob",
  }).then((response) => {
  });
}