import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import DebouncedButton from "features/common/DebouncedButton";
import FormattedMessage from "features/common/TranslatedMessage";
import Question from "../Question";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { createAnswersAnonymous as createAnswersAndUpdateCase } from "slices/answerSlice";
import { DocGenerator } from "utils";
import { useStyles } from "./styles";
import { Typography } from "@material-ui/core";
import { isHotStar, isMuthoot, isOrix, isStar } from "Routes/Routes";
import { getSubQuestionsAnonymous } from "slices/questionSlice";

const Questions = ({
  questions,
  activeDispute,
  activeCase,
  answerLoading,
  profile,
  answerValues,
  updateAnswerValues,
}) => {
  let dispatch = useDispatch();

  let history = useHistory();
  const classes = useStyles();
  // const answerValues = new Map();

  function checkMendatoryAnswer(questionId) {
    for (var entry of answerValues.entries()) {
      if (entry[0] === questionId) {
        return entry[1];
      }
    }
  }

  function checkMendatoryBoolean(questionId) {
    for (var entry of answerValues.entries()) {
      if (entry[0] === questionId) {
        return entry[1];
      }
    }
  }

  const onChange = (question, event) => {
    const value = event.target.value;
    if(isOrix && (question.name === 'Complaint Category')){
      dispatch(getSubQuestionsAnonymous(activeDispute.company_product, activeCase.type, profile.user.email, value));
    }
  };

  const onSubmit = (e) => {
    questions.map((question, index) => {
      if (
        !question.optional &&
        question.type !== "boolean" &&
        question.type !== "date"
      ) {
        var returned_ans = checkMendatoryAnswer(question.id);
        if (typeof returned_ans === "undefined") {
          alert(
            "Please fill mandatory field => " +
              question.name.substr(0, 40) +
              "...."
          );
        } else if (returned_ans === "") {
          alert(
            "Please fill mandatory field => " +
              question.name.substr(0, 40) +
              "...."
          );

          answerValues.delete(question.id);
        }
      }

      if (
        !question.optional &&
        question.mandatory_boolean &&
        question.type === "boolean"
      ) {
        var returned_ans = checkMendatoryBoolean(question.id);
        if (!returned_ans) {
          alert("Please Agree with: " + question.name.substr(0, 40) + "....");

          answerValues.delete(question.id);
        }
      }

      if (!question.optional && question.type === "time") {
        let arr = answerValues.get(question.id).split(":");
        if (arr[0] > 5 || arr[1] > 59 || arr[2] > 59) {
          alert("Please enter time less than 5:59:59");
          answerValues.delete(question.id);
        }
      }

      if (question.type === "time_range") {
        let arr = answerValues.get(question.id).split("to");
        console.log("arr", arr);
        if (arr.length > 1) {
          let fromArr = arr[0].split(":");
          let toArr = arr[1].split(":");
          let fromTimeInSeconds =
            parseInt(fromArr[0]) * 60 * 60 +
            parseInt(fromArr[1]) * 60 +
            parseInt(fromArr[2]);
          let ToTimeInSeconds =
            parseInt(toArr[0]) * 60 * 60 +
            parseInt(toArr[1]) * 60 +
            parseInt(toArr[2]);

          if((fromTimeInSeconds >= ToTimeInSeconds) && fromTimeInSeconds>0) {
            alert(
              "Please enter 'from' time less than 'to' time in : " +
                question.name.substr(0, 40) +
                "...."
            );
            answerValues.delete(question.id);
          }
        }
      }
    });

    if (answerValues.size === questions.length) {
      let createAnswerPayload = [];
      for (var entry of answerValues.entries()) {
        createAnswerPayload.push({
          question: entry[0],
          value: entry[1] !== null ? entry[1].toString() : null,
          case: activeCase.id,
        });
      }

      let casePayload = {
        id: activeCase.id,
        case_status: "questions_complete",
      };

      // alert("Yay! filled all");

      dispatch(
        createAnswersAndUpdateCase(
          { createAnswerPayload, casePayload },
          profile.user.email
        )
      );
    }
  };

  return (
    <React.Fragment>
      <Box textAlign="center" m="0 auto">
        <Grid container>
          <Grid item xs sm md={2}></Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={8}
            data-testid={`Claimant-arbitration-questions`}
          >
            <Box height="1rem"></Box>
            {questions.map((question, index) => {
              if (question.optional) {
                if (question.type === "boolean") {
                  updateAnswerValues(question.id, false);
                } else if (question.pre_filled) {
                  updateAnswerValues(
                    question.id,
                    "Not aware to the best of my knowledge"
                  );
                } else if (question.type === "date") {
                  updateAnswerValues(question.id, null);
                } else if (question.type === "time_range") {
                  updateAnswerValues(
                    question.id,
                    0 + ":" + 0 + ":" + 0 + " to " + 0 + ":" + 0 + ":" + 0
                  );
                } else if (question.type === "simple") {
                  updateAnswerValues(question.id, null);
                }
              } else if (
                question.mandatory_boolean &&
                question.type === "boolean"
              ) {
                updateAnswerValues(question.id, false);
              } else if (!question.optional && question.type === "boolean") {
                updateAnswerValues(question.id, false);
              }

              // if(question.type == "date"){
              // }
              return (
                <Paper key={question.id} className={classes.paper}>
                  <Question
                    index={index}
                    question={question}
                    answerValues={answerValues}
                    updateAnswerValues={updateAnswerValues}
                    onChange={onChange}
                  />
                </Paper>
              );
            })}
          </Grid>
          <Grid item xs sm md={2}></Grid>
        </Grid>
        {profile.profile_type == "general" && (isHotStar || isStar) && (
          <Grid container>
            <Grid item xs sm md={2}></Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              data-testid={`Claimant-arbitration-questions`}
            >
              <Box height="1rem"></Box>
              <Typography>
                In your Grievance, to the extent possible, please indicate
                provisions of the relevant Laws / Rules invoked, Title / Name of
                Content (if any), Time-stamp details Of Content (i.e., specific
                time(s), Where the scene(s) appears / appear in the content (if
                any) and any other information or details that you deem
                necessary.
              </Typography>
              <Box height="1rem"></Box>
            </Grid>
            <Grid item xs sm md={2}></Grid>
          </Grid>
        )}
      </Box>

      <Box display="flex" justifyContent="center" p="1rem 0">
        {questions[0].for_profile_type === "respondent" && (
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => {
              DocGenerator(activeDispute.id, "claimant");
            }}
          >
            Download Case file
          </Button>
        )}

        <Box width="2rem" />
        <DebouncedButton
          variant="outlined"
          color="primary"
          className={classes.button}
          onClickHandler={onSubmit}
          disabled={answerLoading}
          loading={answerLoading}
          value={isMuthoot || isOrix ? 'Save Draft and Proceed' : <FormattedMessage id="buttons.submit" />}
          debounceTime={1000}
        />
      </Box>
    </React.Fragment>
  );
};

export default Questions;
