export const CLAIMANT = "claimant";
export const RESPONDENT = "respondent";
export const VENDOR = "vendor";
export const GENERAL = "general";
export const ARBITRATOR = "arbitrator";
export const MEDIATOR = "mediator";
export const ARBITRATION = "arbitration";
export const ADVOCATE = "lawyer";
export const MEDIATION = "mediation";
export const NEGOTIATION = "negotiation";
export const ADJUDICATION = "adjudication";
export const GRIEVANCE_REDRESSAL = "grievance redressal";
export const GENERAL_RV = "general_rv";
export const SUB_ADMIN = "sub_admin";
export const SUB_ADMIN_RV = "sub_admin_rv";
export const CASE_MANAGER = "case_manager";
export const TDSAT_SECRETARIAT = "secretariat";
export const TDSAT_CASE_MANAGER = "case_manager";
export const TDSAT_SUB_ADMIN_TIAC_RV = "sub_admin_tiac_rv";

export const WITNESS_RV = "witness_rv";
export const ARBITRATOR_RV = "arbitrator_rv";
export const ADVOCATE_RV = "lawyer_rv";
export const SECRETARIAT = "secretariat_rv";

export const CLAIMANT_COMPLETE = "claimant_complete";
export const UPLOAD_COMPLETE = "upload_complete";
export const COMPLETE = "complete";

//case Status
export const REVIEW_COMPLETE = "review_complete";
// rv disputes status values
export const RESPONDENT_COMPLETE = "respondent_complete";
export const RESPONDENT_INPROGRESS = "respondent_inprogress";
export const CLAIMANT_ADVOCATE_COMPLETE = "claimant_lawyer_complete";
export const RESPONDENT_ADVOCATE_COMPLETE = "respondent_lawyer_complete";
export const ARBITRATOR_COMPLETE = "arbitrator_complete";
export const NEW = "new";
export const IO_INPROGRESS = "io_inprogress";
export const IO_COMPLETE = "io_complete";
export const MD_INPROGRESS = "md_inprogress";
export const MD_COMPLETE = "md_complete";
export const FINAL_RESPONDENT_INPROGRESS = "final_respondent_inprogress";
export const FINAL_RESPONDENT_COMPLETE = "final_respondent_complete";

export const AGREEMENT_SUCCESS = "success";
export const AGREEMENT_FAILED = "failure";

export const LOCAL_AUTH = "local";

export const NBF_LOGIN = "NBF_LOGIN";
// export const NBF_URL = "localhost";
export const NBF_URL = "newsbroadcastersfederation.webnyay.in";

export const HOTSTAR_LOGIN = "HOTSTAR_LOGIN";
// export const HOTSTAR_URL = "localhost";
export const HOTSTAR_URL = "hotstar.webnyay.in";
export const DEV_HOTSTAR_URL = "dev-hotstar.webnyay.in";
// todo gcp change url
export const GCP_DEV_HOTSTAR_URL = "hotstar-app-frontend-dev-test.webnyay.in";
export const GCP_PROD_HOTSTAR_URL = "hotstar-app-frontend-prod-test.webnyay.in";
export const STAR_URL = "star.webnyay.ai";
export const Muthoot_URL = "muthoot.webnyay.ai";
export const ORIX_URL = "orix.webnyay.ai";
export const VIACOM_URL = "abc.webnyay.in";
export const CDR_URL = "cdr.webnyay.in";
export const WEBNYAY_URL = "app.webnyay.in";
export const DEV_WEBNYAY_URL = "dev-app.webnyay.in";
// todo gcp change url
export const GCP_DEV_WEBNYAY_URL = "webnyay-app-frontend-dev-test.webnyay.in";
export const GCP_PROD_WEBNYAY_URL = "webnyay-app-frontend-prod-test.webnyay.in";
export const BARANDBENCH_URL = "barandbench.webnyay.in";
export const DIGIPUB_URL = "digipub.webnyay.in";
export const NSE_URL = "nse.webnyay.in";
export const TIAC_URL = "tiac.webnyaya.com";
export const ARB_URL = "arb.webnyay.in";
export const KILUMAYA_URL = "kilumaya.webnyay.ai";
export const TIAC_URL_STATIC = "webnyaya.com";
export const MOOT_URL = "moot.webnyay.in";
export const VIKALP_URL = "vikalp.webnyay.in";
export const SEBI_URL = "smartodr.webnyay.ai";
export const MSME_URL = "msme-sasnagar.webnyay.ai";
export const NDML_FORM_URL_DEV =
  "https://pilot.surepay.ndml.in/SurePayPayment/sp/processRequest";
export const NDML_FORM_URL =
  "https://surepay.ndml.in/SurePayPayment/sp/processRequest";
export const STATES = [
  "AHMEDABAD",
  "ANDAMAN AND NICOBAR",
  "ANDHRA PRADESH",
  "ARUNACHAL PRADESH",
  "ASSAM",
  "BIHAR",
  "CHANDIGARH",
  "CHHATTISGARH",
  "DADRA & NAGAR HAVELI",
  "DAMAN & DIU",
  "DELHI",
  "GOA",
  "GUJARAT",
  "HARYANA",
  "HIMACHAL PRADESH",
  "JAMMU & KASHMIR",
  "JHARKHAND",
  "KARNATAKA",
  "KERALA",
  "MADHYA PRADESH",
  "MAHARASHTRA",
  "MANIPUR",
  "ODISHA",
  "PONDICHERRY(UT)",
  "PUNJAB",
  "RAJASTHAN",
  "SIKKIM",
  "TAMIL NADU",
  "TELANGANA",
  "TRIPURA",
  "UTTAR PRADESH",
  "UTTARAKHAND",
  "WEST BENGAL",
];
export const ACTION_TYPES = [
  "Adjourned",
  "Adjourned Sine Die",
  "Partly Allowed",
  "Before Mediation",
  "Sine Die",
];
export const DISPOSAL_NATURES = [
  "Allowed",
  "Disposed Of",
  "Dismissed",
  "Dismissed As Withdrawn",
  "Dismissed As Infructuous",
  "Be No Order As To Costs",
  "Dismissed As Not Pressed",
  "ALLOWED",
  "Disposed Of As Not Maintainable",
  "Decreed",
  "Disposed Of As Withdrawn",
  "Dismissed For Non Prosecution",
  "Rejected",
  "Converted Into Appeal",
  "Converted Into Ma",
  "Disposed Of As Via Settlement",
  "Disposed Of As Infructuous",
  "Disposed Of As Not Pressed",
  "Partly Allowed And Disposed Of",
  "Dismissed As Not Procecution",
  "Partly Allowed",
  "Decree Passed",
  "Disposed Of As Withdrawn",
  "Converted Into Appeal",
  "Dismissed For Default",
];
export const HEAD_OF_NEXT_HEARING_CHOICES = [
  "For Pronouncement of Judgment",
  "To Be Mentioned",
  "For Preliminary Hearing",
  "For Directions",
  "For Orders",
  "For Issues",
  "For Evidence",
  "For Hearing",
  "EX-PARTE HEARING",
  "For Pronouncement of Order",
  "dkm",
  "FOR PRONOUNCEMENT",
  "ORDER RESERVED",
  "TRANSFER TO MEDIATION",
  "SINE DIE",
  "TO BE MENTIONED (FOR WITHDRAWAL)",
  "FOR DIRECTIONS (LOCKDOWN MATTERS)",
  "TO BE LISTED AS REMINDER (NO DATE)",
];

export const INDIAN_STATES = [
  "Andaman and Nicobar Islands",
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chandigarh",
  "Chhattisgarh",
  "Dadra and Nagar Haveli and Daman and Diu",
  "Delhi",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu and Kashmir",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Ladakh",
  "Lakshadweep",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Puducherry",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
];
