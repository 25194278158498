import React, { useEffect } from 'react';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from '@material-ui/lab';
import { Box, Button, Typography, makeStyles } from '@material-ui/core';
import { CheckCircleOutline, ErrorOutline, RadioButtonUnchecked } from '@material-ui/icons';
import { format } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  timelineContainer: {
    padding: theme.spacing(2),
    backgroundColor: 'white',
    borderRadius: '4px',
    width: '100%',
  },
  timelineItem: {
    '&:before': {
      display: 'none',
    },
  },
  dotDefault: {
    color: theme.palette.primary.main,
  },
  dotError: {
    color: theme.palette.error.main,
  },
  dotPending: {
    color: '#858D98',
  },
  timelineContent: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginRight: '20px'
  },
  timelineHeading: {
    marginBottom: "0.5rem",
    fontSize:'1.3rem',
    lineHeight:'1.8rem',
    color:'black'
  },
}));

const TimelineComponent = ({timelineData, status, handleDownloadFile, isDownloadable=false}) => {
  const classes = useStyles();

  const renderIcon = (status) => {
    return status === 'completed' ? <CheckCircleOutline className={classes.dotDefault} /> : status === 'error' ? <ErrorOutline className={classes.dotError} /> : <RadioButtonUnchecked className={classes.dotPending}/> ;
  };

  return (
    <div className={classes.timelineContainer}>
      {/* <Box style={{borderBottom:'1px solid #dfdfdf', margin:'0 8px', paddingBottom:'8px'}}>
        <Box style={{display:'flex',alignItems:'center',gap:'1rem',justifyContent:'space-between'}}>
          <Typography className={classes.timelineHeading}>
            Timeline
          </Typography>
          <Typography style={{padding:'0.125rem 0.5rem',borderRadius:'4px',backgroundColor:'#FFEFEB',fontSize:'0.94rem'}}>
            {status}
          </Typography>
        </Box>
        <Box style={{display:'flex',alignItems:'center',gap:'1rem'}}>
          <Typography style={{color:'#4b4e56',fontFamily: 'Satoshi-Bold'}}>
            Last Updated
          </Typography>
          <Typography>
            {format(new Date(timelineData[timelineData.length - 1]?.updated_at || null), "dd/MM/yy")}
          </Typography>
        </Box>
      </Box> */}
      <Timeline>
        {timelineData.map((item, index) => (
          <TimelineItem key={index} className={classes.timelineItem}>
            <TimelineSeparator className={classes.timelineContent}>
                <Typography variant="body1" style={{width:'4.4rem',lineHeight:'normal'}}>{format(new Date(item.updated_at),`dd/MM/yy\n h:mm a`)}</Typography>
            </TimelineSeparator>
            <TimelineSeparator className={classes.timelineContent}>
            <TimelineConnector style={(index === timelineData.length - 1) ? {backgroundColor:'#4B4E56'} : {backgroundColor:'#F7F8FB'}} />
            </TimelineSeparator>
            <TimelineSeparator className={classes.timelineContent} style={{margin:0}}>
              {renderIcon(item.status)}
            </TimelineSeparator>
            <TimelineContent className={classes.timelineContent} style={{flex:'none',display:'flex',flexDirection:"column"}}>
              <Typography variant="body1">{item.heading}</Typography>
              {item?.download && isDownloadable && (
                <Button
                  style={{backgroundColor:'#EEF2F5',color:'#4B4E56',marginTop:'4px',width:'fit-content',padding:'4px 10px'}}
                  size='small'
                  onClick={() => handleDownloadFile(item?.download, item?.download_key)}
                >
                  Download
                </Button>
              )}
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </div>
  );
};

export default TimelineComponent;