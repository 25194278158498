import {
  Box,
  Button,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import DebouncedButton from "features/common/DebouncedButton";
import FormattedMessage from "features/common/TranslatedMessage";
import { DropzoneDialog } from "material-ui-dropzone";
import mime from "mime-types";
import React from "react";
import { useDispatch } from "react-redux";
import { updateCaseAnonymous } from "slices/casesSlice";
import {
  addUserFileAnonymous,
  deleteUserFileAnonymous,
} from "slices/disputeSlice";
import { DocGeneratorAnonymous } from "utils";
import SingleDetail from "../SingleDetail";
import instance from "api/globalaxios";
import { isMuthoot, isOrix } from "Routes/Routes";

const useStyles = makeStyles((theme) => ({}));

const renderDetails = ({
  questions,
  answers,
  profile,
  getQuestionsAnswers,
  addToogledAnswer,
  removeToogledAnswer,
}) => {
  return questions.map((question, index) => {
    let answer = answers.find((ans) => ans.question === question.id);
    return (
      <SingleDetail
        key={question.id}
        question={question}
        answer={answer}
        profile={profile}
        getQuestionsAnswers={getQuestionsAnswers}
        addToogledAnswer={addToogledAnswer}
        removeToogledAnswer={removeToogledAnswer}
      />
    );
  });
};

const renderFiles = (files, deleteFile) => {
  return files.length !== 0
    ? files.map((file, index) => {
        return (
          <Box key={index} textAlign="center">
            <Paper style={{ padding: "2rem", margin: "1rem" }}>
              <Typography variant="subtitle2">{file.name}</Typography>
              <IconButton
                aria-label="delete"
                color="primary"
                onClick={() => {
                  deleteFile(file.id);
                }}
              >
                <DeleteOutlineIcon />
              </IconButton>
            </Paper>
          </Box>
        );
      })
    : null;
};

export const UploadDialog = ({ open, setOpen, addFile, allFiles }) => {
  const classes = useStyles();
  let totalFiles = allFiles;
  return (
    <Box textAlign="center">
      <Paper
        className={classes.paper}
        style={{ padding: "3rem", maxWidth: "100%", margin: "1rem" }}
      >
        <Box>
          <IconButton
            className={classes.icon}
            aria-label="delete"
            // color="primary"
            onClick={() => {
              setOpen(true);
            }}
          >
            <AddCircleOutlineIcon className={classes.icon} />
          </IconButton>
        </Box>
      </Paper>

      <DropzoneDialog
        cancelButtonText={"cancel"}
        submitButtonText={"submit"}
        maxFileSize={104857600}
        open={open}
        onClose={() => setOpen(false)}
        onSave={(files) => {
          files.forEach((file) => {
            if (totalFiles < 4) {
              addFile(file);
              totalFiles += 1;
            }
          });
          setOpen(false);
        }}
        showPreviews={true}
        filesLimit={4}
        showFileNamesInPreview={true}
        acceptedFiles={[
          "image/jpeg",
          "image/png",
          "image/jpg",
          "application/msword",
          "application/pdf",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ]}
      />
    </Box>
  );
};

const DetailsReview = ({
  activeDispute,
  activeCase,
  questions,
  answers,
  profile,
  userFiles: files,
  getQuestionsAnswers,
}) => {
  let dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  const updateDisputeDescriptionAnonymous = async (disputeBody) => {
    try {
      let response = await instance({
        method: "post",
        url: "updatedisputedescriptionanonymous/",
        data: disputeBody,
      }); 
      return response.data.id;
    } catch (error) {
      console.log(error);
    }
  }

  const isDescriptionQuestion = (question) => {
    const MUTHOOT_QUESTION = "What is the grievance related to? Please mention in brief.";
    const ORIX_QUESTION = "Grievance Description in brief (Max. 1000 characters)";
    return question === MUTHOOT_QUESTION || question === ORIX_QUESTION;
  }

  const onClick = async () => {
    if (toogledAnswers.length > 0) {
      alert("save all the answers first.");
    } else {
      let casePayload = {
        id: activeCase.id,
        case_status: "review_complete",
      };
      const DISPUTE_DESCRIPTION_QID = questions.find((ques) => isDescriptionQuestion(ques.name))?.id;
      let disputePayload = {
        id: DISPUTE_DESCRIPTION_QID,
        value: answers.find((ans) => ans.question === DISPUTE_DESCRIPTION_QID),
      }
      setLoading(true);
      await dispatch(updateCaseAnonymous(casePayload, profile.user.email));
      if(disputePayload?.value){
        updateDisputeDescriptionAnonymous(disputePayload);
      }
      setLoading(false);
    }
  };

  const [toogledAnswers, setToogledAnswers] = React.useState([]);
  const addToogledAnswer = (id) => {
    let temp = [...toogledAnswers];
    let index = temp.indexOf(id);
    if (index <= -1) temp.push(id);
    setToogledAnswers(temp);
  };
  const removeToogledAnswer = (id) => {
    let temp = [...toogledAnswers];
    let index = temp.indexOf(id);
    if (index > -1) temp.splice(index, 1);
    setToogledAnswers(temp);
  };

  const [open, setOpen] = React.useState(false);

  async function addFile(file) {
    const data = new FormData();
    data.append(file.name, file);
    const fileBody = {
      dispute: activeCase.dispute,
      path: 123,
      file,
      profile: profile.id,
      type: mime.lookup(file.name),
      name: file.name,
      size: file.size,
      is_attachment: true,
    };
    await dispatch(addUserFileAnonymous(fileBody, profile.user.email));
  }
  async function deleteFile(id) {
    await dispatch(
      deleteUserFileAnonymous(id, activeCase.dispute, profile.user.email)
    );
  }

  return (
    <Box textAlign="center" p="2rem 0">
      <Grid container>
        <Grid item xs sm md={2}></Grid>
        <Grid item xs={12} sm={12} md={8}>
          <Typography variant="h4">
            <FormattedMessage id="pages.review.review" />
          </Typography>
          <Box height="2rem"></Box>

          {renderDetails({
            questions,
            answers,
            profile,
            getQuestionsAnswers,
            addToogledAnswer,
            removeToogledAnswer,
          })}
        </Grid>
        <Grid item xs sm md={2}></Grid>
      </Grid>

      <Box height="2rem" />
      <Grid container>
        <Grid item xs sm md={2}></Grid>
        <Grid item xs={12} sm={12} md={8}>
          <Typography variant="h4">Documents</Typography>
          <Box height="2rem"></Box>

          {renderFiles(files, deleteFile)}
          {files.length !== 4 && (
            <UploadDialog
              open={open}
              setOpen={setOpen}
              addFile={addFile}
              allFiles={files.length}
            />
          )}
        </Grid>
        <Grid item xs sm md={2}></Grid>
      </Grid>
      <Box height="1.5rem"></Box>

      <Box display="flex" justifyContent="center">
        <Box width="2rem"></Box>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => {
            DocGeneratorAnonymous(
              activeCase.dispute,
              questions[0].case_file_type,
              profile.user.email
            );
          }}
        >
          {isMuthoot || isOrix ? 'Download Draft' : <FormattedMessage id="buttons.download" />}
        </Button>

        <div style={{ width: "2rem" }} />

        <DebouncedButton
          variant="outlined"
          color="primary"
          size="large"
          onClickHandler={onClick}
          disabled={loading}
          loading={loading}
          value={isMuthoot || isOrix ? 'Submit' : <FormattedMessage id="buttons.next" />}
          debounceTime={1000}
        />
      </Box>
    </Box>
  );
};

export default DetailsReview;
