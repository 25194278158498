// Set config defaults when creating the instance

import history from "app/history";
import axios from "axios";
import axiosRetry from "axios-retry";
import {
  isCDR,
  isDev,
  isDigipub,
  isHotStar,
  isLocalBackend,
  isNbf,
  isNSE,
  isRv,
  isVikalp,
} from "Routes/Routes";
import {
  isLoggedIn,
  isLoggedInDPCGCAdmin,
  isLoggedInHotstarAdmin,
  logout,
} from "slices/loginSlice";
import Swal from "sweetalert2";
import { getChatAuthToken } from "utils/localStorage";

export let Url = "";
let linkedin_url = "";
let admin_dpcgc_url = "";
let admin_hotstar_url = "";
let chatHost = "rocket-chat-dev-3.webnyay.in";

if (isDev) {
  // todo gcp change url
  // admin_dpcgc_url = "https://dev-api.dpcgc.org/";
  admin_dpcgc_url = "https://dpcgc-app-backend-dev-test.webnyay.in/";
  admin_hotstar_url = "https://dev-api-hotstar.webnyay.in/";
  // admin_hotstar_url = "https://dev-api-hotstar-backend-test.webnyay.in/";
} else {
  admin_dpcgc_url = "https://api.dpcgc.org/";
  // admin_dpcgc_url = "https://dpcgc-backend-prod-test.webnyay.in/";
  admin_hotstar_url = "https://api-hotstar.webnyay.in/";
  // admin_hotstar_url = "https://hotstar-backend-prod-test.webnyay.in/";
}
if (!isDev) {
  chatHost = "rocketchat-webnyay-backend-prod-test.webnyay.in";
}

if (isHotStar && !isDev) {
  // todo gcp change url
  Url = "https://api-hotstar.webnyay.in/";
  // Url = "https://hotstar-backend-prod-test.webnyay.in/";
  linkedin_url = "https://hotstar.webnyay.in/linkedin-callback";
} else if (isHotStar && isDev) {
  // todo gcp change url
  Url = "https://dev-api-hotstar.webnyay.in/";
  // Url = "https://dev-api-hotstar-backend-test.webnyay.in/";
  linkedin_url = "https://hotstar.webnyay.in/linkedin-callback";
} else if (isDev) {
  // todo gcp change url
  Url = "https://dev-api.webnyay.in/";
  // Url = "https://dev-api-test.webnyay.in/";
  linkedin_url = "https://dev-app.webnyay.in/linkedin-callback";
  // linkedin_url =
  //   "https://webnyay-app-frontend-dev-test.webnyay.in/linkedin-callback";
} else if (isDigipub) {
  Url = "https://api.webnyay.in/";
  linkedin_url = "https://digipub.webnyay.in/linkedin-callback";
} else if (isRv) {
  Url = "https://api.webnyay.in/";
  linkedin_url = "https://resolvevirtually.com/linkedin-callback";
} else if (isNbf) {
  Url = "https://api.webnyay.in/";
  linkedin_url =
    "https://newsbroadcastersfederation.webnyay.in/linkedin-callback";
} else if (isCDR) {
  Url = "https://api.webnyay.in/";
  linkedin_url = "https://cdr.webnyay.in/linkedin-callback";
} else if (isVikalp) {
  Url = "https://api.webnyay.in/";
  linkedin_url = "https://vikalp.webnyay.in/linkedin-callback";
} else if (isNSE) {
  Url = "https://api.webnyay.in/";
  linkedin_url = "https://nse.webnyay.in/linkedin-callback";
} else {
  // todo gcp change url
  Url = "https://api.webnyay.in/";
  // Url = "https://webnyay-backend-prod-test.webnyay.in/";
  linkedin_url = "https://app.webnyay.in/linkedin-callback";
}

let timeout = 60000;
if (isLocalBackend) {
  Url = "http://127.0.0.1:8000/";
  timeout = 300000;
}

// PRODUCTION used when run on production
export const baseUrl = Url;
// export const chatHost = "vc.webnyay.in:4000";
export const chatUrl = `https://${chatHost}`;
export const websocketUrl = `wss://${chatHost}/websocket`;
export const linkedinRedirectUrl = linkedin_url;

// LOCAL used when run on local
// export const baseUrl = "http://127.0.0.1:8000/";
// export const chatHost = "127.0.0.1:3000";
// export const chatUrl = `http://${chatHost}`;
// export const websocketUrl = `ws://${chatHost}/websocket`;
// export const linkedinRedirectUrl = "http://localhost:4000/linkedin-callback";

export const linkedinClientid = "78c3qwij21k8f3";

export const linkedinOauthurl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${linkedinClientid}&scope=r_liteprofile%20r_emailaddress&state=123456&redirect_uri=${linkedinRedirectUrl}`;

export const instanceHotstar = axios.create({
  baseURL: admin_hotstar_url,
  timeout: 30000,
});

instanceHotstar.interceptors.request.use(function (config) {
  const token = isLoggedInHotstarAdmin();
  if (token) {
    config.headers.Authorization = "Bearer " + token;
  }
  return config;
});

export const instanceDPCGC = axios.create({
  baseURL: admin_dpcgc_url,
  timeout: 30000,
});

instanceDPCGC.interceptors.request.use(function (config) {
  const token = isLoggedInDPCGCAdmin();
  if (token) {
    config.headers.Authorization = "Bearer " + token;
  }
  return config;
});

export const instance = axios.create({
  baseURL: baseUrl,
  timeout: timeout,
});

export const chatInstance = axios.create({
  baseURL: chatUrl,
  timeout: 30000,
});

chatInstance.interceptors.request.use(function (config) {
  const { token, userId } = getChatAuthToken();

  if (token) {
    config.headers["X-Auth-Token"] = token;
    config.headers["X-User-Id"] = userId;
  }
  return config;
});

axiosRetry(instance, {
  retries: 5, // number of retries
  retryDelay: (retryCount) => {
    console.log(`retry attempt: ${retryCount}`);
    return retryCount * 1000; // time interval between retries
  },
  retryCondition: (error) => {
    // console.log(error.response.status);
    // if retry condition is not specified, by default idempotent requests are retried
    return (
      !error.response ||
      (error.response.status >= 501 && error.response.status <= 504)
    );
  },
});
axiosRetry(instanceDPCGC, {
  retries: 5, // number of retries
  retryDelay: (retryCount) => {
    console.log(`retry attempt: ${retryCount}`);
    return retryCount * 1000; // time interval between retries
  },
  retryCondition: (error) => {
    // console.log(error.response.status);
    // if retry condition is not specified, by default idempotent requests are retried
    return (
      !error.response ||
      (error.response.status >= 501 && error.response.status <= 504)
    );
  },
});
axiosRetry(instanceHotstar, {
  retries: 5, // number of retries
  retryDelay: (retryCount) => {
    console.log(`retry attempt: ${retryCount}`);
    return retryCount * 1000; // time interval between retries
  },
  retryCondition: (error) => {
    // console.log(error.response.status);
    // if retry condition is not specified, by default idempotent requests are retried
    return (
      !error.response ||
      (error.response.status >= 501 && error.response.status <= 504)
    );
  },
});

instance.interceptors.request.use(function (config) {
  const token = isLoggedIn();
  if (token) {
    config.headers.Authorization = "Bearer " + token;
  }
  return config;
});

export function errorInterceptor(store) {
  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      console.log(error);
      let { status } = error.request;
      console.log("status", status);
      if (status === 0 && isHotStar) {
        // || status === 502 || status === 503
        history.push("/maintenance");
      } else if (status === 0 || status === 404 || status === 500) {
        history.push("/fallback");
      } else if (status === 401) {
        let timerInterval;
        Swal.fire({
          title: "Session Expired",
          html: "Your session has expired. You will be redirected to login page",
          timer: 2000,
          timerProgressBar: true,
          onBeforeOpen: () => {
            Swal.showLoading();
            timerInterval = setInterval(() => {
              const content = Swal.getContent();
              if (content) {
                const b = content.querySelector("b");
                if (b) {
                  b.textContent = Swal.getTimerLeft();
                }
              }
            }, 100);
          },
          onClose: () => {
            clearInterval(timerInterval);
          },
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
            store.dispatch(logout());
            history.push("/login");
          }
        });
      }
      return Promise.reject(error);
    }
  );
}

export default instance;
