import React from "react";
import { Box, Typography, Link } from "@material-ui/core";

const Annexure2 = () => {
  return (
    <Box
      style={{
        maxWidth: "700px",
        margin: "50px auto",
        padding: "20px",
        lineHeight: "1.6",
      }}
    >
      <Typography variant="body1" style={{ marginBottom: '2rem' }}>
        Dear Sir/Madam,
      </Typography>

      <Typography variant="body1" style={{ marginBottom: '2rem' }}>
        Greetings from Orix India!
      </Typography>

      <Typography variant="body1" style={{ marginBottom: '2rem' }}>
        We wish to inform that prior to filing a complaint at <strong>Grievance Redressal Cell</strong>, a customer must first raise his/her concern on the email ids given below for <strong>Level 1</strong> resolution:-
      </Typography>

      <ul style={{ marginBottom: "2rem", paddingLeft: "20px" }}>
        <li>
          <Typography variant="body1">
            Loan Against Property / Commercial Vehicle / Any Other Loan:{" "}
            <Link href="mailto:customercare@orixindia.com">
              customercare@orixindia.com
            </Link>
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            Lease Business:{" "}
            <Link href="mailto:lease.foreclosure@orixindia.com">
              lease.foreclosure@orixindia.com
            </Link>
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            Any other business:{" "}
            <Link href="mailto:customercare@orixindia.com">
              customercare@orixindia.com
            </Link>
          </Typography>
        </li>
      </ul>

      <Typography variant="body1" style={{ marginBottom: '2rem' }}>
        If you do not get a satisfactory response within 15 days from the date of submission of your concern at aforementioned Email IDs, you may approach the Grievance Redressal Cell for further action.
      </Typography>

      <Typography variant="body1" style={{ marginTop: '2rem' }}>
        Thanks,
      </Typography>
      <Typography variant="body1">Orix India Customer Service Desk</Typography>
    </Box>
  );
};

export default Annexure2;
